import { downloadUrl } from './download'

export async function MinioDownload(filePath) {
  const url = '/apinew/common/downMinio?fileName=' + filePath
  await downloadUrl(url)
}

export async function MinioUpload(file, store) {
  const url = 'apinew/common/fileToMinio'
  const fd = new FormData()
  fd.append('file', file)
  try {
    const response = await store.$axios.post(url, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response?.status === 200 && response?.data?.rtnMessage === 'success') {
      return { status: true, content: response.data }
    } else {
      return { status: false, content: response.data };
    }
  } catch (e) {
    store.dispatch('growl/error', { title: '提示', message: response?.data?.rtnMessage }, { root: true });
    return { status: false, content: e }
  }
}