<script>
import FormValidation from '@shell/mixins/form-validation';
import SortableTable from '@shell/components/SortableTable';
import algoedit from '../../../../../components/algos/algoedit.vue'
import DeleteModal from '@shell/components/DeleteModal';
import dayjs from 'dayjs'
import { BadgeState } from '@components/BadgeState';
import ServiceModal from '@shell/components/service/ServiceModal.vue';
import Masthead from '@shell/components/ResourceList/Masthead';

export default {
  name: 'Algos',
  layout: 'home',
  mixins: [FormValidation],
  components: {
    SortableTable, algoedit, BadgeState, DeleteModal, ServiceModal, Masthead
  },
  data() {
    return {
      fvFormRuleSets: [{
        path: 'algoform', rules: ['Algo']
      }, {
        path: 'algoEditForm', rules: ['AlgoEdit']
      },],
      headers: [
        { name: 'name', label: '算法名称' },
        { name: 'version', label: '算法版本' },
        { name: 'type', label: '算法类型' },
        { name: 'status', label: '算法状态' },
        { name: 'usage', label: '算法用途' },
        { name: 'desc', label: '算法描述' },
        { name: 'createTime', label: '时间' },
        { name: 'btnactions', label: '操作' }
      ],
      algoStatus: {
        '0': '上传文件',
        '3': '上传失败',
        '5': '打包中',
        '10': '打包完成',
        '20': '打包失败',
      },
      receiverActionMenuIsOpen: false,
      actionMenuTargetElement: null,
      actionMenuTargetEvent: null,
      rows: [],
      imagerows: [],
      allimages: [],
      currentPage: 1,
      pageSize: 12,
      total: 0,
      selectedItemId: null,
      interval: null,
      algoName: '',
      mode: '_ADD',
      editObj: null,
      publishAlgo: null
    }
  },
  created() {
    this.fetchData();
    this.fetchImageData()
    this.interval = setInterval(this.fetchData, 5000)
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval);
  },
  methods: {
    addalgo() {
      this.mode = '_ADD'
      this.$modal.show('algomodal')
    },
    addPublishModal(obj) {
      if (obj.status !== 10) {
        this.$store.dispatch('growl/warning', {
          title: '提示',
          message: '该算法还未打包完成!',
          timeout: 3000,
        }, { root: true });
        return
      } else {
        this.publishAlgo = obj
        this.$modal.show('pushmodal')
      }

    },
    deleteAlgoById(val) {
      this.$refs.deleteAlgo.show()
      this.selectedItemId = val
    },
    updateAlgoById(val) {
      val.status === 20 ? this.mode = '_REUPLOAD' : this.mode = '_EDIT'
      this.editObj = val
      this.$modal.show('algomodal')
    },
    query() {
      this.currentPage = 1
      this.fetchData()
    },
    async deleteAlgoFunc() {
      const url = '/apinew/algo/' + this.selectedItemId
      try {
        const response = await this.$axios.delete(url);
        if (response?.status === 200 && response?.data.rtnMessage === 'success') {
          this.fetchData();
          this.$refs.deleteAlgo.close()
          this.$store.dispatch('growl/success', { title: '提示', message: '删除成功！' }, { root: true });
          this.selectedItemId = null
        }
      } catch (e) { }
    },
    close() {
      this.$modal.hide('algomodal')
    },
    dayFormat(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
    },
    showMenu(show, id) {
      if (this.$refs['popover' + id] && show) {
        this.$refs['popover' + id].show();
      } else {
        this.$refs['popover' + id].hide();
      }
    },
    async fetchData() {
      const url = this.algoName !== '' ? `/apinew/algo/?name=${this.algoName}&page=${this.currentPage}&size=${this.pageSize}` : `/apinew/algo/?page=${this.currentPage}&size=${this.pageSize}`
      try {
        const response = await this.$axios.get(url);
        if (response?.status === 200) {
          this.total = response.data.data.total
          this.rows = response.data.data.records
        }
      } catch (e) { }
    },
    async fetchImageData() {
      const url = '/apinew/image/?status=1&page=1&size=10000'
      try {
        const response = await this.$axios.get(url);
        if (response?.status === 200) {
          const imageArr = []
          this.allimages = response.data.data.records
          this.allimages.map(item => {
            imageArr.push(item.name)
          })
          this.imagerows = imageArr
        }
      } catch (e) { }
    },
    pageChange(page) {
      this.currentPage = page
      this.fetchData()
    }
  }
}
</script>

<template>
  <div class="algos-page">
    <Masthead
      resource = 'algo'
    >
      <template slot="extraActions">
        <button type="button" class="btn ml-6 role-primary" @click="addalgo"><i class="icon icon-plus" />新增算法</button>
      </template>
    </Masthead>
    <div class="actions">
      <div class="search">
        <input placeholder="请输入内容" v-model="algoName" />
        <button type="button" class="btn ml-6 role-primary" @click="query"><i class="icon icon-search" />查询</button>
      </div>
    </div>
    <div class="algosbox">
      <SortableTable :headers="headers" :rows="rows" key-field="id" :search="false" :paging="true" :compatible="false"
        :total="total" :rows-per-page="pageSize" :currentPage="currentPage" :row-actions="false" :table-actions="false"
        @pageChange="pageChange">
        <template #cell:createTime="{ row }">
          {{ dayFormat(row.createTime) }}
        </template>
        <template #cell:type="{ row }">
          <BadgeState :label="row.type === 1 ? '普通算法' : row.type === 2 ? '训练算法' : '普通、训练算法'"
            :color="row.type === 1 ? 'bg-error' : row.type === 2 ? 'bg-warning' : 'bg-info'" />
        </template>
        <template #cell:status="{ row }">
          <BadgeState v-tooltip="row.message" :label="algoStatus[row.status]" color="bg-info" />
        </template>
        <template #cell:btnactions="{ row }">
          <div class="btnactions">
            <button type="button" class="btn btn-sm role-multi-action" v-tooltip="'算法镜像地址:' + row.compressedMinioAddress">
              <i class="icon icon-error" />&nbsp;&nbsp;详情
            </button>
            <button type="button" class="btn btn-sm role-edit-action" @click="updateAlgoById(row)">
              <i class="icon icon-edit" />&nbsp;&nbsp;编辑
            </button>
            <button type="button" class="btn btn-sm role-copy-action" @click='addPublishModal(row)'>
              <i class="icon icon-copy" />&nbsp;&nbsp;发布
            </button>
            <div class="user user-menu" data-testid="nav_header_showUserMenu" tabindex="0" @blur="showMenu(false, row.id)"
              @click="showMenu(true, row.id)" @focus.capture="showMenu(true, row.id)">
              <v-popover :ref="'popover' + row.id" placement="bottom-end" offset="-10" trigger="manual"
                :delay="{ show: 0, hide: 0 }" :popper-options="{ modifiers: { flip: { enabled: false } } }"
                :container="false">
                <div class="popoverbox">
                  <button ref="moreactionbtn" type="button" class="btn btn-sm role-multi-action moreactionbtn">
                    <i class="icon icon-actions" />
                  </button>
                </div>
                <template slot="popover" class="actions-menu">
                  <ul class="list-unstyled dropdown" data-testid="user-menu-dropdown"
                    @click.stop="showMenu(false, row.id)">
                    <li class="user-info">
                      <button type="button" class="btn btn-sm role-delete-action" @click="deleteAlgoById(row.id)">
                        <i class="icon icon-delete" />&nbsp;&nbsp;删除
                      </button>
                    </li>

                  </ul>
                </template>
              </v-popover>
            </div>
          </div>
        </template>
      </SortableTable>
    </div>

    <!-- 新增算法弹框 -->
    <modal name="algomodal" width="50%" :click-to-close="false" :height="'auto'" class="modal" data-testid="mvc__card">
      <div class="algos">
        <div class="header">
          {{ mode === '_EDIT' || mode === '_REUPLOAD' ? '编辑' : '新增' }}算法<i class="icon icon-close"
            @click="$modal.hide('algomodal')"></i>
        </div>
        <div class="algocontainer">
          <algoedit :mode="mode" :editObj="editObj" :imagerows="imagerows" :allimages="allimages"
            :rules="fvGetAndReportPathRules(mode === '_ADD' ? 'algoform' : 'algoEditForm')" @successSave="fetchData"
            @close="close" />
        </div>
      </div>
    </modal>

    <!-- 删除镜像弹框 -->
    <DeleteModal ref="deleteAlgo" label="确定删除该算法?" @disable="deleteAlgoFunc" />

    <!-- 新增服务发布 -->
    <modal name="pushmodal" width="50%" :click-to-close="false" :height="'auto'" class="modal" data-testid="mvc__card">
      <div class="algos">
        <div class="header">
          {{ publishAlgo?.name }}发布<i class="icon icon-close" @click="$modal.hide('pushmodal')"></i>
        </div>
        <div class="algocontainer">
          <ServiceModal :algo="publishAlgo" @close="$modal.hide('pushmodal')" />
        </div>
      </div>
    </modal>
  </div>
</template>

<style lang='scss' scoped>
.algos-page {
  background-color: #ffffff;
  margin: 16px;
  height: 100%;
  padding: 0 24px 24px 24px !important;
  overflow: hidden;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .search {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        width: 280px;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        padding: 6px 8px 6px 8px;
        margin-right: 8px;
      }
    }

  }

  .algosbox {
    height: calc(100% - 138px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(238, 238, 238, 1) !important;
      border-radius: 2.5px !important;
    }
  }
}

::v-deep {
  .statuslabel {
    span {
      width: 12px;
      height: 12px;
      margin-right: 6px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .statuslabel[data-status="0"] {
    color: rgba(235, 151, 68, 1);

    span {
      background-color: rgba(235, 151, 68, 1);
    }
  }

  .statuslabel[data-status="1"] {
    color: #4759C5;

    span {
      background-color: #4759C5;
    }
  }

  .statuslabel[data-status="2"] {
    color: rgba(153, 153, 153, 1);

    span {
      background-color: rgba(153, 153, 153, 1);
    }
  }

  .btnactions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 0px;

    .moreactionbtn {
      transform: rotateZ(90deg);
      margin-top: 8px;

      i {
        color: var(--lighterText);
      }
    }
  }
}

::v-deep {
  .algos {
    .header {
      height: 20px;
      opacity: 1;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 20px;
      margin-bottom: 10px;
      color: var(--default);
      position: relative;
      text-align: center;

      i {
        position: absolute;
        right: 0px;
        top: 3px;
        font-size: 14px;
        color: var(--lighterText);
        cursor: pointer;
      }
    }

    .algocontainer {
      width: 100%;
      height: auto;
      padding: 22px 0px 0px 0px;
    }
  }
}

.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>


