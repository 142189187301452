<template>
    <div class="common">
        <CommonAdmin title="数据集" :headers="headers" :url="url" type="dataset" :tableAction="tableAction" />
    </div>
</template>
  
<script>
import CommonAdmin from '@shell/components/CommonAdmin'
import { datasetApi } from '@shell/components/common/api'
export default {
    name: 'DataSet',
    components: {
        CommonAdmin
    },
    data() {
        return {
            headers: [
                { name: 'name', label: '名称' },
                { name: 'desc', label: '描述' },
                { name: 'type', label: '类型' },
                { name: 'status', label: '状态' },
                { name: 'statusMessage', label: '状态消息' },
                { name: 'statusTime', label: '状态时间戳' },
                { name: 'createTime', label: '创建时间' },
                { name: 'btnactions', label: '操作' }
            ],
            url: datasetApi.dataset,
            tableAction: [
                { key: 'query', text: "详情", btnclass: 'multi', iconclass: 'error', clickEvent: 'queryDetail', eventParams: [] },
                { key: 'edit', text: "编辑", btnclass: 'edit', iconclass: 'edit', clickEvent: "addNewModal", eventParams: ['edit'] },
                { key: 'del', text: "删除", btnclass: 'delete', iconclass: 'delete', clickEvent: 'delItemById', eventParams: [] },
            ]
        }
    },
}
</script>

  