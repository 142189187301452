<template>
    <div class="common">
        <CommonAdmin title="训练数据" :headers="headers" :url="url" type="train" :customRows="customRows"
            :tableAction="tableAction" />
    </div>
</template>
  
<script>
import CommonAdmin from '@shell/components/CommonAdmin'
import { datasetApi } from '@shell/components/common/api'
export default {
    name: 'TrainData',
    components: {
        CommonAdmin
    },
    data() {
        return {
            headers: [
                { name: 'name', label: '名称' },
                { name: 'desc', label: '描述' },
                { name: 'dataSetNum', label: '数据集数量' },
                { name: 'createTime', label: '创建时间' },
                { name: 'btnactions', label: '操作' }
            ],
            url: datasetApi.traindata,
            customRows: {},
            tableAction: [
                { key: 'query', text: "详情", btnclass: 'multi', iconclass: 'error', clickEvent: 'queryDetail', eventParams: [] },
                { key: 'edit', text: "编辑", btnclass: 'edit', iconclass: 'edit', clickEvent: "addNewModal", eventParams: ['edit'] },
                { key: 'del', text: "删除", btnclass: 'delete', iconclass: 'delete', clickEvent: 'delItemById', eventParams: [] },
            ]

        }
    },
    created() {
        this.fetchdata()
    },
    methods: {
        fetchdata() {
            const url = datasetApi.dataset + '/?createUser=cstor' + '&page=1' + '&size=10000'
            this.$axios.get(url).then(res => {
                const datasetrows = res.data.data.records.filter(item => item.status === 'imported')
                this.customRows.dataset = datasetrows.map((item) => {
                    return {
                        label: item.name,
                        value: item
                    };
                });
            })
        },
    }
}
</script>
  