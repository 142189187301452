<script>
import FileSelector from '@shell/components/form/FileSelector';
import { NORMAN } from '@shell/config/types';
import FormValidation from '@shell/mixins/form-validation';
import Select from '@shell/components/form/Select';
import Error from '@shell/components/form/Error';
import AsyncButton from '@shell/components/AsyncButton';
import CodeMirrorWithTitle from '@shell/components/CodeMirrorWithTitle';
import UnitInput from '@shell/components/form/UnitInput';
import { RadioGroup } from '@components/Form/Radio';
import { datasetApi } from './api'
import { MinioUpload } from '@shell/utils/minio';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'CommonModal',
    components: { Select, Error, FileSelector, AsyncButton, RadioGroup, CodeMirrorWithTitle, UnitInput },
    mixins: [FormValidation],
    props: {
        mode: {
            type: String,
            default: '',
        },
        rules: {
            default: () => [],
            type: Array,
            validator: (rules) => rules.every((rule) => ['function'].includes(typeof rule))
        },
        type: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => { },
        },
        customRows: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            typeMap: {
                dataset: '数据集',
                train: '训练数据',
                task: '训练任务',
            },
            dataset: {
                id: 0,
                name: '',
                desc: '',
                type: 'file',
                datafile: '',
                fileurl: '',
                databaseurl: '',
                num: '',
                datasetname: '',
                datasets: [],
                trainenv: undefined,
                traindata: undefined,
                trainparams: {
                    title: 'application',
                    format: 'yaml',
                    content: '{"minio": {"url": "http://data-minio.192.168.2.192.sslip.io", "port": 9000, "access": "minioadmin", "bucket": "cstor", "remove": "/api/remove", "secret": "minioadmin", "server": "minio-ext.storage-center", "upload": "/api/uploadone", "download": "/api/download"}, "server": {"port": 8080, "servlet": {"context-path": "/api"}}, "spring": {"mvc": {"pathmatch": {"matching-strategy": "ant_path_matcher"}}, "servlet": {"multipart": {"max-file-size": "1000MB", "max-request-size": "1000MB"}}, "datasource": {"url": "jdbc:mysql://192.168.2.192:3306/data_center?useUnicode=true&characterEncoding=utf8&zeroDateTimeBehavior=convertToNull&useSSL=true&serverTimezone=Asia/Shanghai&allowMultiQueries=true", "password": "cstorfs", "username": "root", "driverClassName": "com.mysql.cj.jdbc.Driver"}, "application": {"name": "backend-bigmodel"}}, "mybatis": {"mapperLocations": "classpath*:cn/cstor/backend/bigmodel/mapper/*Mapper.xml", "type-handlers-package": "cn.cstor.backend.bigmodel.mapper"}}'
                },
                trainconfig: {
                    requestsCpu: '1',
                    limitsCpu: '4',
                    requestsMemory: '1',
                    limitsMemory: '4',
                    requestsGpu: '',
                    limitsGpu: ''
                },
            },
            useExistingLabels: [
                '文件',
                '数据库',
                'URL'
            ],
            useExistingOptions: ['file', 'database', 'url'],
            file: null,
            fvFormRuleSets: [{
                path: 'commonform', rules: []
            }],
            validationflag: false
        }
    },
    watch: {
        type: {
            immediate: true,
            handler: function (type) {
                this.fvFormRuleSets[0].rules[0] = type;
            }
        }
    },
    mounted() {
        if (this.type === 'dataset' && this.mode === 'edit') {
            this.dataset.id = this.config.id
            this.dataset.name = this.config.name
            this.dataset.desc = this.config.desc
            this.dataset.databaseurl = this.config.databaseurl
            this.dataset.fileurl = this.config.fileurl
            this.dataset.type = this.config.type === 0 ? 'database' : this.config.type === 1 ? 'file' : 'url'
        }
        if (this.type === 'train' && this.mode === 'edit') {
            this.dataset.id = this.config.id
            this.dataset.name = this.config.name
            this.dataset.desc = this.config.desc
            this.dataset.datasets = this.config.dataSets
        }
        if (this.type === 'task' && this.mode === 'edit') {
            this.dataset.id = this.config.id
            this.dataset.name = this.config.name
            this.dataset.desc = this.config.desc
            this.dataset.traindata = this.customRows?.traindata.filter(env => env.value.id === this.config.trainingidsId)[0]
            this.dataset.trainenv = this.customRows?.trainenv.filter(env => env.value.id === this.config.algoId)[0]
            this.dataset.trainconfig = {
                requestsCpu: this.config.cpu,
                limitsCpu: this.config.cpuLimit,
                requestsMemory: this.config.memory,
                limitsMemory: this.config.memoryLimit,
                requestsGpu: this.config.gpu,
                limitsGpu: this.config.gpuMemory
            }
        }
    },
    computed: {
        principal() {
            return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
        },
    },
    created() {
        this.queueUpdate = debounce(this.updateExpression, 500);
        this.queueTitleUpdate = debounce(this.updateTitleExpression, 500);
    },
    methods: {
        updateTitleExpression(value) {
            this.$set(this.dataset.trainparams, 'title', value);
        },
        updateExpression(value) {
            this.$set(this.dataset.trainparams, 'content', value);
        },
        closeModal() {
            this.$emit('finish')
        },
        onKeySelected(data) {
            if (!data.file.name.endsWith('.tar.gz')) {
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查文件格式' }, { root: true });
                return
            } else {
                this.file = data.file
                this.dataset.datasetname = data.name
            }
        },
        save(buttonCb) {
            const funcMap = {
                'dataset': () => this.saveDataset(buttonCb),
                'train': () => this.saveTrainDataset(buttonCb),
                'task': () => this.saveTrainTask(buttonCb)
            }
            funcMap[this.type]()
        },
        saveDataset(buttonCb) {
            const funcSaveDataSetMap = {
                'file': () => this.saveDatasetByFile(buttonCb),
                'database': () => this.saveDatasetByDataBase(buttonCb),
                'url': () => this.saveDatasetByUrl(buttonCb),
            }
            funcSaveDataSetMap[this.dataset.type]()
        },
        updateDataSet(url, id, data, buttonCb) {
            this.$axios.put(url + '/' + id, data).then(res => {
                this.successRes(buttonCb, res)
            }).catch(() => {
                buttonCb(false)
                this.$store.dispatch('growl/error', { title: '提示', message: this.mode === 'edit' ? this.typeMap[this.type] + '编辑失败!' : this.typeMap[this.type] + '创建失败!', timeout: 1000 }, { root: true });
            })
        },
        successRes(buttonCb, res) {
            buttonCb(false)
            if (res && res.data.rtnCode === 0) {
                this.$store.dispatch('growl/success', { title: '提示', message: this.mode === 'edit' ? this.typeMap[this.type] + '编辑成功!' : this.typeMap[this.type] + '创建成功!', timeout: 1000 }, { root: true });
                this.$emit('finish', res.data.rtnCode)
            } else {
                this.$store.dispatch('growl/error', { title: '提示', message: this.mode === 'edit' ? this.typeMap[this.type] + '编辑成功!' : this.typeMap[this.type] + '创建成功!', timeout: 1000 }, { root: true });
            }
        },
        ToMinio(id, url, buttonCb) {
            const paramsurl = {
                'database': datasetApi.mysqlToMinio + '?id=' + id + '&uri=' + url,
                'url': datasetApi.urlDataToMinio + '?id=' + id + '&url=' + url,
            }
            this.$axios.post(paramsurl[this.dataset.type]).then(res => {
                this.successRes(buttonCb, res)
            }).catch(() => {
                buttonCb(false)
                this.$store.dispatch('growl/error', { title: '提示', message: this.mode === 'edit' ? '数据集编辑失败!' : '数据集导入失败!', timeout: 1000 }, { root: true });
            })
        },
        async saveDatasetByFile(buttonCb) {
            if (((this.dataset.name === '' || this.dataset.datasetname === '' || this.dataset.desc === '') && this.mode === 'create') || ((this.dataset.name === '' || this.dataset.desc === '') && this.mode === 'edit')) {
                buttonCb(false)
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
            } else if (this.mode === 'create') {
                const { status, content } = await MinioUpload(this.file, this.$store)
                this.dataset.fileurl = content.data
                if (status && content.rtnCode === 0) {
                    const initdata = {
                        createUser: this.principal.loginName,
                        name: this.dataset.name,
                        desc: this.dataset.desc,
                        type: 1,
                    }
                    const res = await this.$axios.post(datasetApi.dataset, initdata)
                    if (res && res.data.rtnCode === 0) {
                        const data = {
                            minioPath: this.dataset.fileurl
                        }
                        this.updateDataSet(datasetApi.dataset, res.data.data?.id, data, buttonCb)
                    } else {
                        buttonCb(false)
                        this.$store.dispatch('growl/error', { title: '提示', message: '数据集导入失败!', timeout: 1000 }, { root: true });
                    }
                }
            } else {
                const editData = {
                    id: this.dataset.id,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                }
                this.updateDataSet(this.dataset.id, editData, buttonCb)
            }
        },
        async saveDatasetByDataBase(buttonCb) {
            if (((this.dataset.name === '' || this.dataset.databaseurl === '' || this.dataset.desc === '') && this.mode === 'create') || ((this.dataset.name === '' || this.dataset.desc === '') && this.mode === 'edit')) {
                buttonCb(false)
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
            } else if (this.mode === 'create') {
                const initdata = {
                    createUser: this.principal.loginName,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    type: 0,
                }
                const res = await this.$axios.post(datasetApi.dataset, initdata)
                if (res && res.data.rtnCode === 0) {
                    this.ToMinio(res.data.data.id, this.dataset.databaseurl, buttonCb)
                } else {
                    buttonCb(false)
                    this.$store.dispatch('growl/error', { title: '提示', message: '数据集导入失败!', timeout: 1000 }, { root: true });
                }

            } else {
                const editData = {
                    id: this.dataset.id,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                }
                this.updateDataSet(datasetApi.dataset, this.dataset.id, editData, buttonCb)
            }
        },
        async saveDatasetByUrl(buttonCb) {
            if (((this.dataset.name === '' || this.dataset.fileurl === '' || this.dataset.desc === '') && this.mode === 'create') || ((this.dataset.name === '' || this.dataset.desc === '') && this.mode === 'edit')) {
                buttonCb(false)
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
            } else if (this.mode === 'create') {
                const initdata = {
                    createUser: this.principal.loginName,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    type: 2,
                }
                const res = await this.$axios.post(datasetApi.dataset, initdata)
                if (res && res.data.rtnCode === 0) {
                    this.ToMinio(res.data.data.id, this.dataset.fileurl, buttonCb)
                } else {
                    buttonCb(false)
                    this.$store.dispatch('growl/error', { title: '提示', message: '数据集导入失败!', timeout: 1000 }, { root: true });
                }
            } else {
                const editData = {
                    id: this.dataset.id,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                }
                this.updateDataSet(datasetApi.dataset, this.dataset.id, editData, buttonCb)
            }
        },
        async saveTrainDataset(buttonCb) {
            if (((this.dataset.name === '' || this.dataset.datasets.length === 0 || this.dataset.desc === '') && this.mode === 'create') || ((this.dataset.name === '' || this.dataset.desc === '') && this.mode === 'edit')) {
                buttonCb(false)
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
            } else if (this.mode === 'create') {
                const initdata = {
                    createUser: this.principal.loginName,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    dataSets: this.dataset.datasets,
                    dataSetNum: this.dataset.datasets.length
                }
                this.$axios.post(datasetApi.traindata, initdata).then(res => {
                    this.successRes(buttonCb, res)
                })
            } else {
                const editData = {
                    id: this.dataset.id,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    dataSets: this.dataset.datasets,
                }
                this.updateDataSet(datasetApi.traindata, this.dataset.id, editData, buttonCb)
            }
        },
        async saveTrainTask(buttonCb) {
            if (!this.validationflag) {
                buttonCb(false)
                this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
            } else if (this.mode === 'create') {
                const initdata = {
                    createUser: this.principal.loginName,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    algoId: this.dataset.trainenv.id,
                    algoName: this.dataset.trainenv.targetImageName,
                    trainingidsId: this.dataset.traindata.id,
                    trainingidsName: this.dataset.traindata.name,
                    trainPara: this.dataset.trainparams.content,
                    trainParaName: this.dataset.trainparams.title + '.' + this.dataset.trainparams.format,
                    cpu: this.dataset.trainconfig.requestsCpu,
                    memory: this.dataset.trainconfig.requestsMemory,
                    cpuLimit: this.dataset.trainconfig.limitsCpu,
                    memoryLimit: this.dataset.trainconfig.limitsMemory,
                    gpu: this.dataset.trainconfig.requestsGpu,
                    gpuMemory: this.dataset.trainconfig.limitsGpu,
                }
                this.$axios.post(datasetApi.traintask, initdata).then(res => {
                    this.successRes(buttonCb, res)
                })
            } else {
                const editData = {
                    id: this.dataset.id,
                    name: this.dataset.name,
                    desc: this.dataset.desc,
                    algoId: this.dataset.trainenv.id,
                    algoName: this.dataset.trainenv.targetImageName,
                    trainingidsId: this.dataset.traindata.id,
                    trainingidsName: this.dataset.traindata.name,
                    trainPara: this.dataset.trainparams.content,
                    trainParaName: this.dataset.trainparams.title + '.' + this.dataset.trainparams.format,
                    cpu: this.dataset.trainconfig.requestsCpu,
                    memory: this.dataset.trainconfig.requestsMemory,
                    cpuLimit: this.dataset.trainconfig.limitsCpu,
                    memoryLimit: this.dataset.trainconfig.limitsMemory,
                    gpu: this.dataset.trainconfig.requestsGpu,
                    gpuMemory: this.dataset.trainconfig.limitsGpu,
                }
                this.updateDataSet(datasetApi.traintask, this.dataset.id, editData, buttonCb)
            }
        },
        validationMessage(msg) {
            this.validationflag = false
            if (msg.length === 0) {
                this.validationflag = true
            }
        }
    },
};
</script>

<template>
    <div class="dataseteditform">
        <div class="dataset-row">
            <div class="dataset-item">
                <span class="label">名称: </span>
                <input ref="dataset-name" v-model="dataset.name" type="text">
            </div>
            <div class="dataset-item">
                <span class="label">描述: </span>
                <input ref="version" v-model="dataset.desc">
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item">
                <span class="label">训练环境: </span>
                <Select :options="customRows?.trainenv || []" v-model="dataset.trainenv" placeholder="选择训练环境" />
            </div>
            <div class="dataset-item">
                <span class="label">训练数据: </span>
                <Select :options="customRows?.traindata || []" v-model="dataset.traindata" placeholder="选择训练数据" />
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item" style="width:100%">
                <span class="label">训练参数: </span>
                <div class="textcontrainer">
                    <CodeMirrorWithTitle :options="{ showCursorWhenSelecting: true, foldGutter: true }"
                        :asTextArea="false" :code="dataset.trainparams" @onInput="queueUpdate"
                        @onTitleInput="queueTitleUpdate" />
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item resources" style="width:100%">
                <div class="header">
                    <span>训练资源</span>
                </div>
                <div class="resources_inputs">
                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.requestsCpu"
                                :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                                :label="t('containerResourceLimit.requestsCpu')" mode="create" :increment="1"
                                :output-modifier="true" base-unit="CPU" data-testid="cpu-reservation" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.limitsCpu"
                                :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                                :label="t('containerResourceLimit.limitsCpu')" mode="create" :increment="1"
                                :output-modifier="true" base-unit="CPU" data-testid="cpu-limit" />
                        </span>

                    </div>

                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.requestsMemory"
                                :placeholder="t('containerResourceLimit.memPlaceholder')"
                                :label="t('containerResourceLimit.requestsMemory')" mode="create" :increment="1"
                                :output-modifier="true" base-unit="Memory" data-testid="memory-reservation" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.limitsMemory"
                                :placeholder="t('containerResourceLimit.memPlaceholder')"
                                :label="t('containerResourceLimit.limitsMemory')" mode="create" :increment="1"
                                :output-modifier="true" base-unit="Memory" data-testid="memory-limit" />
                        </span>
                    </div>
                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.requestsGpu" placeholder="1" label="GPU预留"
                                base-unit="GPU" mode="create" :increment="1" :output-modifier="true"
                                data-testid="Gpu-limit" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="dataset.trainconfig.limitsGpu" placeholder="1" label="GPU限制"
                                base-unit="GPU" mode="create" :increment="1" :output-modifier="true"
                                data-testid="Gpu-limit" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'train'">
            <div class="dataset-item" style="width: 100%;margin-bottom: -24px;">
                <span class="label">数据集列表: </span>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'train'">
            <div class="dataset-item" style="width: 100%;">
                <div class="datasetlist">
                    <div class="left">
                        <div class="formtitle">列表页</div>
                        <div class="listitem">
                            <div class="dataitem" v-for="item in dataset.datasets" :key="item">数据集{{
                                item.name
                            }} <i class="icon icon-close"
                                    @click="dataset.datasets.splice(dataset.datasets.indexOf(item), 1);" />
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="formtitle">链接数据集</div>
                        <div class="listitem">
                            <Select :options="customRows?.dataset || []" v-model="dataset.datasets" multiple
                                placeholder="数据集名称">
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'dataset'">
            <div class="dataset-item">
                <span class="label">类型: </span>
                <RadioGroup v-model="dataset.type" name="datasetType" :labels="useExistingLabels"
                    :disabled="mode === 'edit'" :options="useExistingOptions" />
            </div>
            <div class="dataset-item" v-if="dataset.type === 'database'">
                <span class="label">连接URL: </span>
                <input ref="dataset-usage" :disabled="mode === 'edit'"
                    placeholder="mysql://root:cstorfs@192.168.2.192:3306/data_center?table=algo"
                    v-model="dataset.databaseurl" type="text">
            </div>
            <div class="dataset-item" v-if="dataset.type === 'url'">
                <span class="label">文件URL: </span>
                <input ref="dataset-usage" :disabled="mode === 'edit'"
                    placeholder="http://192.168.2.183:8120/ceshi-suanfa.tar.gz" v-model="dataset.fileurl" type="text">
            </div>
            <div class="dataset-item" v-if="dataset.type === 'file'">
                <span class="label">上传文件包: </span>
                <FileSelector style="background-color: transparent;borderColor: transparent;boxShadow: none;"
                    :label.sync="dataset.datasetname" :disabled="mode === 'edit'" :include-file-name="true"
                    :accept="'.xz,.gz'" :closeIcon="true" @selected="onKeySelected">
                    <template #icon>
                        <i class="icon icon-upload"></i>
                    </template>
                </FileSelector>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'dataset'">


        </div>
        <Error :value="{ ...dataset }" :rules="fvGetAndReportPathRules('commonform')"
            @validationMessage="validationMessage" />
        <div class="footer">
            <button type="button" class="btn btn-border" @click="closeModal">
                取消
            </button>
            <AsyncButton id="datasetsubmit" data-testid="dataset-update-submit" class="btn role-primary" type="submit"
                :action-label="mode === 'create' ? '创建' : '编辑'"
                :waiting-label="mode === 'create' ? '创建并开始导入' : '编辑并开始导入'"
                :success-label="mode === 'create' ? '创建成功' : '编辑成功'" :error-label="mode === 'create' ? '创建失败' : '编辑失败'"
                @click="save" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.dataseteditform {
    display: flex;
    flex-direction: column;
    gap: 38px;
    font-family: 'Microsoft Yahei';
    max-height: 500px;
    overflow-y: scroll;

    .dataset-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;

        .dataset-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            overflow: hidden;

            .label {
                min-width: 84px;
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 20px;
                color: var(--lightText);
                text-align: left;
            }

            .textcontrainer {
                width: calc(100% - 84px);
                border: 1px solid var(--border);
                display: flex;
                flex-direction: column;

                .paramsbox {
                    min-height: 24px;
                    flex: 1;
                    display: flex;
                    border: none;
                    border-bottom: 1px solid var(--border);

                    .desc {
                        flex: 1;
                        background-color: rgb(241, 248, 255);
                        color: var(--primary);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .num {
                        flex: 1;
                        color: var(--lightText);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .paramsbox:last-child {
                    border-bottom: none;
                }
            }

            input {
                width: 100%;
                height: 32px;
                opacity: 1;
                border-radius: 4px;
            }

            .icon-upload {
                font-size: 24px;
                opacity: 1;
                color: rgba(23, 124, 255, 1);
                cursor: pointer;
            }

            .datasetlist {
                width: 100%;
                height: auto;
                padding: 15px 40px;
                border: 1px solid var(--border);
                display: flex;
                justify-content: flex-start;

                .left {
                    width: 100%;
                    padding-right: 21px;

                    .formtitle {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20px;
                        color: var(--lightText);
                        text-align: left;
                        margin-bottom: 12px;
                    }

                    .listitem {
                        display: flex;
                        flex-wrap: wrap;
                        row-gap: 10px;

                        .dataitem {
                            display: block;
                            width: 110px;
                            height: 30px;
                            line-height: 30px;
                            margin-right: 12px;
                            opacity: 1;
                            border-radius: 4px;
                            background-color: var(--info);
                            color: var(--lightText);
                            cursor: pointer;
                            padding-right: 12px;
                            padding-left: 4px;
                            position: relative;
                            @include textOver;

                            i {
                                transform: translateY(2px);
                                position: absolute;
                                right: 4px;
                                top: 7px;
                                display: none;
                            }

                            &:hover {
                                color: var(--primary);
                                background-color: rgb(241, 248, 255);

                                i {
                                    display: inline-block;
                                }
                            }
                        }
                    }


                }

                .right {
                    width: 318px;
                    padding-left: 21px;
                    border-left: 1px solid var(--border);

                    .formtitle {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 20px;
                        color: var(--lightText);
                        text-align: left;
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .resources {
            border-radius: 6px;
            border: 1.5px solid var(--border);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .header {
                width: 108px;
                height: 38px;
                opacity: 1;
                border-radius: 6px;
                background: rgba(237, 245, 255, 1);
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    width: 86.8px;
                    height: 26.13px;
                    opacity: 1;
                    border-radius: 6px;
                    background: #ffffff;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: var(--primary);
                    display: block;
                    line-height: 26.13px;
                }
            }

            .resources_inputs {
                width: 100%;
                padding: 0 40px;

                ::v-deep {
                    label {
                        width: 80px;
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}
</style>
