<script>
import {
    DATA_STATUS_MAP, DATA_TYPE_MAP, TASK_STATUS_MAP
} from '@shell/config/mapConfig'
import { dayFormatByCommon } from '@shell/utils/time';
import CodeMirrorWithTitle from '@shell/components/CodeMirrorWithTitle';
import UnitInput from '@shell/components/form/UnitInput';

export default {
    name: 'DatasetDetailModal',
    components: {
        CodeMirrorWithTitle, UnitInput
    },
    data() {
        return {
            DATA_STATUS_MAP,
            DATA_TYPE_MAP,
            TASK_STATUS_MAP
        }
    },
    props: {
        config: {
            default: () => { },
            type: Object
        },
        type: {
            default: '',
            type: String
        },
        customRows: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        handleTrainEnv() {
            if (this.type === 'task') {
                const trainenv = this.customRows?.trainenv.filter(env => env.value.id === this.config.algoId)
                return trainenv[0] ? trainenv[0].label : ''
            } else {
                return ''
            }
        },
        handleTrainparams() {
            return {
                title: this.config.trainParaName ? this.config.trainParaName.split('.')[0] : '',
                format: this.config.trainParaName ? this.config.trainParaName.split('.')[1] : '',
                content: this.config.trainPara || ''
            }
        }
    },
    methods: {
        dayFormatByCommon,
        handleReport(backendResponse) {
            if (backendResponse && backendResponse !== null && backendResponse.indexOf('\n') !== -1) {
                const responseArray = backendResponse.split('\n');
                const results = responseArray.map(item => {
                    const [title, num] = item.split(/(\d+%)/);
                    return { title: title.trim(), num: num.trim() };
                });
                return results
            }
        }
    }
};
</script>

<template>
    <div class="dataseteditform">
        <div class="dataset-row">
            <div class="dataset-item">
                <label class="label">名称: </label>
                <span class="desc" :title="config?.name">{{ config?.name }}</span>
            </div>
            <div class="dataset-item">
                <label class="label">描述: </label>
                <span class="desc" :title="config?.desc">{{ config?.desc }}</span>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item">
                <label class="label">训练环境: </label>
                <span class="desc" :title="handleTrainEnv">{{ handleTrainEnv }}</span>
            </div>
            <div class="dataset-item">
                <label class="label">训练数据: </label>
                <span class="desc" :title="config?.trainingidsName">{{ config?.trainingidsName }}</span>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item" style="width:100%">
                <span class="label">训练参数: </span>
                <div class="textcontrainer">
                    <CodeMirrorWithTitle :options="{ showCursorWhenSelecting: true, foldGutter: true }" :asTextArea="false"
                        :code="handleTrainparams" mode='view' />
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item resources" style="width:100%">
                <div class="header">
                    <span>训练资源</span>
                </div>
                <div class="resources_inputs">
                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="config.cpu" :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                                :label="t('containerResourceLimit.requestsCpu')" mode="view" :increment="1"
                                :output-modifier="true" base-unit="CPU" data-testid="cpu-reservation" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="config.cpuLimit" :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                                :label="t('containerResourceLimit.limitsCpu')" mode="view" :increment="1"
                                :output-modifier="true" base-unit="CPU" data-testid="cpu-limit" />
                        </span>

                    </div>

                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="config.memory" :placeholder="t('containerResourceLimit.memPlaceholder')"
                                :label="t('containerResourceLimit.requestsMemory')" mode="view" :increment="1"
                                :output-modifier="true" base-unit="Memory" data-testid="memory-reservation" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="config.memoryLimit"
                                :placeholder="t('containerResourceLimit.memPlaceholder')"
                                :label="t('containerResourceLimit.limitsMemory')" mode="view" :increment="1"
                                :output-modifier="true" base-unit="Memory" data-testid="memory-limit" />
                        </span>
                    </div>
                    <div class="row mb-20">
                        <span class="col span-6">
                            <UnitInput v-model="config.gpu" placeholder="1" label="GPU预留" base-unit="GPU" mode="view"
                                :increment="1" :output-modifier="true" data-testid="Gpu-limit" />
                        </span>
                        <span class="col span-6">
                            <UnitInput v-model="config.gpuMemory" placeholder="1" label="GPU限制" base-unit="GPU" mode="view"
                                :increment="1" :output-modifier="true" data-testid="Gpu-limit" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'train'">
            <div class="dataset-item" style="width: 100%;">
                <label class=" label">数据集列表: </label>
                <div class="desc datasetcardbox" v-if="config?.dataSets && config?.dataSets.length > 0">
                    <span v-for="item in config?.dataSets" :key='item' :title="item.name" class="datasetcard">
                        {{ item.name }}
                    </span>
                </div>
                <div class="desc" v-else>
                    暂无数据集
                </div>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'dataset'">
            <div class="dataset-item">
                <label class="label">类型: </label>
                <span class="desc" :title='DATA_TYPE_MAP[config?.type]'>{{ DATA_TYPE_MAP[config?.type] }}</span>
            </div>
            <div class="dataset-item" v-if="config?.minioPath">
                <label class="label">文件地址: </label>
                <span class="desc" :title='config?.minioPath'>{{ config?.minioPath }}</span>
            </div>
            <div class="dataset-item" v-else-if="config?.fileUrl">
                <label class="label">文件地址: </label>
                <span class="desc" :title='config?.fileUrl'>{{ config?.fileUrl }}</span>
            </div>
            <div class="dataset-item" v-else>
                <label class="label">数据源: </label>
                <span class="desc" :title="config?.datasource">{{ config?.datasource }}</span>
            </div>
        </div>
        <div class="dataset-row" v-if="type === 'dataset' || type === 'task'">
            <div class="dataset-item">
                <label class="label">状态: </label>
                <span class="desc"
                    :title="type === 'dataset' ? DATA_STATUS_MAP[config?.status] : type === 'task' ? TASK_STATUS_MAP[config?.status] : config?.status">{{
                        type === 'dataset' ? DATA_STATUS_MAP[config?.status] : type === 'task' ?
                        TASK_STATUS_MAP[config?.status] : config?.status }}</span>
            </div>
            <div class="dataset-item">
                <label class="label">状态时间戳: </label>
                <span class="desc"
                    :title="dayFormatByCommon(config?.timestamp || config?.statustimestamp || config?.statusTime)">{{
                        dayFormatByCommon(config?.timestamp || config?.statustimestamp || config?.statusTime)
                    }}</span>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'task'">
            <div class="dataset-item">
                <label class="label">状态消息: </label>
                <span class="desc" :title="config?.statusMessage">{{ config?.statusMessage }}</span>
            </div>
            <div class="dataset-item" v-show="config?.tarOutMinio !== null && config?.tarOutMinio !== ''">
                <label class="label">结果文件: </label>
                <span class="desc" :title="config?.tarOutMinio || ''">{{ config?.tarOutMinio || '' }}</span>
            </div>
        </div>
        <div class="dataset-row"
            v-show="(type === 'task' || type === 'model') && (config?.tarContent !== null && config?.tarContent !== '')">
            <div class="dataset-item">
                <span class="label">训练报告: </span>
                <div class="textcontrainer">
                    <div class="paramsbox" v-for="(item, index) in handleReport(config?.tarContent)" :key="index">
                        <div class="desc">{{ item?.title }}</div>
                        <div class="num">{{ item?.num }}</div>
                    </div>
                </div>
            </div>
            <div class="dataset-item">
                <span class="label"></span>
            </div>
        </div>
        <div class="dataset-row" v-show="type === 'dataset'">
            <div class="dataset-item">
                <label class="label">状态消息: </label>
                <span class="desc" :title="config?.statusmsg">{{ config?.statusmsg }}</span>
            </div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
.dataseteditform {
    display: flex;
    flex-direction: column;
    gap: 38px;
    font-family: 'Microsoft Yahei';

    .dataset-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .dataset-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;

            .label {
                min-width: 100px;
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 20px;
                color: var(--lightText);
                text-align: left;
            }

            .datasetcardbox {
                width: 100%;
                height: auto;
                padding: 12px;
                border: 1px solid var(--border);
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                justify-content: flex-start;

                .datasetcard {
                    display: block;
                    min-width: 120px;
                    max-width: 120px;
                    padding-left: 12px;
                    padding-right: 12px;
                    height: 30px;
                    line-height: 30px;
                    margin-right: 12px;
                    opacity: 1;
                    border-radius: 4px;
                    background-color: rgb(241, 248, 255);
                    color: var(--primary);
                    @include textOver;
                }
            }

            .textcontrainer {
                width: calc(100% - 84px);
                border: 1px solid var(--border);
                display: flex;
                flex-direction: column;

                .paramsbox {
                    min-height: 24px;
                    flex: 1;
                    display: flex;
                    border: none;
                    border-bottom: 1px solid var(--border);

                    .desc {
                        flex: 1;
                        background-color: rgb(241, 248, 255);
                        color: var(--primary);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .num {
                        flex: 1;
                        color: var(--lightText);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .paramsbox:last-child {
                    border-bottom: none;
                }
            }

            .desc {
                @include textOver;
            }

        }

        .resources {
            border-radius: 6px;
            border: 1.5px solid var(--border);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .header {
                width: 108px;
                height: 38px;
                opacity: 1;
                border-radius: 6px;
                background: rgba(237, 245, 255, 1);
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    width: 86.8px;
                    height: 26.13px;
                    opacity: 1;
                    border-radius: 6px;
                    background: #ffffff;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: var(--primary);
                    display: block;
                    line-height: 26.13px;
                }
            }

            .resources_inputs {
                width: 100%;
                padding: 0 40px;

                ::v-deep {
                    label {
                        width: 80px;
                    }
                }
            }
        }
    }
}
</style>
