

<template>
    <div class="imagecard" :data-type='config?.type'
        :style="{ 'color': theme[config?.type]?.LightenColor, 'backgroundColor': theme[config?.type]?.bgColor }">
        <div class="versiontag">
            <span :style="{ 'backgroundColor': theme[config?.type]?.DarkenColor }">{{ config?.version }}</span>
        </div>
        <div class="cardtop">
            <div class="imagename" :style="{ 'color': theme[config?.type]?.DarkenColor }">{{ config?.name }}</div>
            <div class="imagedesc" :title="config?.info">镜像用途: {{ config?.info }}</div>
            <div class="imagedesc">创建人: {{ config?.createUser }} </div>
            <div class="imagedesc">创建时间: {{ config?.createTime }}</div>
        </div>
        <div class="cardbottom" :style="{ 'borderTopColor': theme[config?.type]?.LightenColor }">
            <div class="actions" :style="{ 'color': theme[config?.type]?.DarkenColor }">
                <i class="icon icon-download" v-tooltip="'下载'" @click="downloadImage(config)" />
                <i class="icon icon-error" v-tooltip="'镜像:' + config?.imageName" />
                <i class="icon icon-delete" v-tooltip="'删除'" @click="deleteImage(config?.id)" />
            </div>
        </div>
    </div>
</template>

<script>
import { MinioDownload } from '@shell/utils/minio';
export default {
    name: 'Imageitem',
    props: {
        config: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            theme: {
                '0': {
                    'LightenColor': 'rgba(240, 194, 149, 1)',
                    'DarkenColor': 'rgba(235, 151, 68, 1)',
                    'bgColor': 'rgba(255, 244, 232, 1)',
                },
                '1': {
                    'LightenColor': 'rgba(15, 87, 229,.4)',
                    'DarkenColor': 'rgba(73, 92, 197,1)',
                    'bgColor': 'rgba(241, 248, 255, 1)',
                },
                '2': {
                    'LightenColor': 'rgba(224, 224, 224, 1)',
                    'DarkenColor': 'rgba(153, 153, 153, 1)',
                    'bgColor': 'rgba(240, 240, 240, 1)',
                }
            }
        }
    },
    methods: {
        deleteImage(id) {
            this.$emit('deleteImage', id)
        },
        async downloadImage(config) {
            MinioDownload(config?.path)
        }
    }
}

</script>

<style lang="scss" scoped>
.imagecard {
    width: 358px;
    height: 180px;
    opacity: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 2px inset;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .versiontag {
        width: 98.9px;
        height: 98.9px;
        position: absolute;
        top: -50px;
        right: -50px;
        transform: rotate(45deg);

        span {
            position: absolute;
            bottom: 0;
            display: block;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0px;
            color: rgba(255, 255, 255, 1);
            width: 98.9px;
            height: 22.81px;
            line-height: 22.81px;
            text-align: center;
        }
    }

    .cardtop {
        height: 100%;
        width: 100%;
        padding: 24px 25px 0px 25px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .imagename {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 20px;
        }

        .imagedesc {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20px;
            color: var(--lighterText);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .cardbottom {
        border-top: 1.5px dashed;
        height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .actions i {
            width: 14px;
            height: 14px;
            opacity: 1;
            cursor: pointer;
        }

        .actions i:not(:last-child) {
            margin-right: 21.5px;
        }

        .actions i:last-child {
            margin-right: 24px;
        }
    }
}

.imagecard:hover {
    color: #ffffff !important;

    .versiontag {
        span {
            background-color: #ffffff !important;
        }
    }

    .cardtop {

        .imagename,
        .imagedesc {
            color: #ffffff !important;
        }
    }

    .cardbottom {
        border-top-color: #ffffff !important;

        .actions {
            color: #ffffff !important;
        }
    }
}

.imagecard[data-type='0']:hover {
    background-color: rgba(235, 151, 68, 1) !important;

    .versiontag {
        span {
            color: rgba(235, 151, 68, 1);
        }
    }

}

.imagecard[data-type='1']:hover {
    background-color: rgba(73, 92, 197, 1) !important;

    .versiontag {
        span {
            color: rgba(73, 92, 197, 1);
        }
    }

}

.imagecard[data-type='2']:hover {
    background-color: rgba(153, 153, 153, 1) !important;

    .versiontag {
        span {
            color: rgba(153, 153, 153, 1);
        }
    }

}
</style>
