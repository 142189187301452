<template>
    <div class="commonadmin-page">
        <Masthead :resource='type'>
            <template slot="extraActions" v-if='isHasHeader'>
                <button type="button" class="btn ml-6 role-primary" @click="addNewModal({}, 'create')"><i
                        class="icon icon-plus" />
                    {{ '创建' + title }}
                </button>

            </template>
        </Masthead>
        <div class="actions">
            <div class="search">
                <input :placeholder="`请输入${title}名称`" v-model="inputval" />
                <button type="button" class="btn ml-6 role-primary" @click="fetchData"><i
                        class="icon icon-search" />查询</button>
            </div>
        </div>
        <div class="contentbox">
            <SortableTable :headers="headers" :rows="rows" key-field="id" :search="false" :paging="true" :compatible="false"
                :total="total" :rows-per-page="pageSize" :currentPage="currentPage" :row-actions="false"
                :table-actions="false" @pageChange="pageChange">
                <template #cell:createTime="{ row }">
                    {{ row.createTime === null ? '' : dayFormatByCommon(row.createTime) }}
                </template>
                <template #cell:statusTime="{ row }">
                    {{ row.statusTime === null ? '' : dayFormatByCommon(row.statusTime) }}
                </template>
                <template #cell:type="{ row }">
                    <BadgeState
                        :color="STATUS_CLASS_MAP[row.type === 0 ? 'warning' : row.type === 1 ? 'error' : 'running'].color"
                        :label="DATA_TYPE_MAP[row.type]" />
                </template>
                <template #cell:status="{ row }">
                    <BadgeState :color="STATUS_CLASS_MAP['info'].color"
                        :label="type === 'task' ? TASK_STATUS_MAP[row.status] : type === 'dataset' ? DATA_STATUS_MAP[row.status] : row.status" />
                </template>
                <template #cell:btnactions="{ row }">
                    <div class="btnactions">
                        <button v-for="item in (tableAction.length > 3 ? tableAction.slice(0, 3) : tableAction)"
                            :key='item.key' v-haskey:[item.key]="lodashMap(tableAction, 'key')" type="button"
                            class='btn btn-sm' :class="'role-' + item.btnclass + '-action'"
                            @click="btnControl(row, item.clickEvent, item.eventParams)">
                            <i class="icon" :class="'icon-' + item.iconclass" />&nbsp;&nbsp;{{ item.text }}
                        </button>

                        <div v-show="tableAction.length > 3" class="user user-menu" data-testid="nav_header_showUserMenu"
                            tabindex="0" @blur="showMenu(false, row.id)" @click="showMenu(true, row.id)"
                            @focus.capture="showMenu(true, row.id)">
                            <v-popover :ref="'popover' + row.id" placement="bottom-end" offset="-10" trigger="manual"
                                :delay="{ show: 0, hide: 0 }" :popper-options="{ modifiers: { flip: { enabled: false } } }"
                                :container="false">
                                <div class="popoverbox">
                                    <button ref="moreactionbtn" type="button"
                                        class="btn btn-sm role-multi-action moreactionbtn">
                                        <i class="icon icon-actions" />
                                    </button>
                                </div>
                                <template slot="popover" class="actions-menu">
                                    <ul class="list-unstyled dropdown" data-testid="user-menu-dropdown"
                                        @click.stop="showMenu(false, row.id)">
                                        <li class="user-info" v-for="item in tableAction.slice(3, tableAction.length) "
                                            :key='item.key'>
                                            <button v-haskey:[item.key]="lodashMap(tableAction, 'key')" type="button"
                                                class='btn btn-sm' :class="'role-' + item.btnclass + '-action'"
                                                @click="btnControl(row, item.clickEvent, item.eventParams)">
                                                <i class="icon" :class="'icon-' + item.iconclass" />&nbsp;&nbsp;{{ item.text
                                                }}
                                            </button>
                                        </li>
                                    </ul>
                                </template>
                            </v-popover>
                        </div>
                    </div>
                </template>
            </SortableTable>
        </div>
        <!-- 创建/编辑弹框 -->
        <modal name="commonmodal" width="50%" :click-to-close="false" :height="'auto'"  class="modal"
            data-testid="mvc__card">
            <div class="services">
                <div class="header">
                    {{ modalTitle }}{{ title }}<i class="icon icon-close" @click="$modal.hide('commonmodal')"></i>
                </div>
                <div class="servicecontainer">
                    <CommonModal :mode="mode" :type="type" :config="selectedItem" :customRows="customRows"
                        @finish="finishModal" />
                </div>
            </div>
        </modal>
        <!-- 详情弹框 -->
        <modal name="commonDetailModal" width="50%" :click-to-close="false" :height="'auto'" class="modal"
            data-testid="mvc__card">
            <div class="services">
                <div class="header">
                    {{ title }}-{{ selectedItem?.name }}<i class="icon icon-close"
                        @click="$modal.hide('commonDetailModal')"></i>
                </div>
                <div class="servicecontainer">
                    <commonDetailModal :config="selectedItem" :type="type" :customRows="customRows" />
                </div>
            </div>
        </modal>
        <!-- 删除确认弹框 -->
        <DeleteModal ref="deleteItemRef" :label="`确定删除该${title}?`" @disable="deleteAItem" />
    </div>
</template>
  
<script>
import { dayFormatByCommon } from '@shell/utils/time';
import SortableTable from '@shell/components/SortableTable';
import { BadgeState } from '@components/BadgeState';
import Masthead from '@shell/components/ResourceList/Masthead';
import CommonModal from '@shell/components/common/commonModal'
import commonDetailModal from '@shell/components/common/commonDetailModal'
import DeleteModal from '@shell/components/DeleteModal';
import {
    STATUS_CLASS_MAP, DATA_STATUS_MAP, DATA_TYPE_MAP, TASK_STATUS_MAP
} from '@shell/config/mapConfig'
import { NORMAN } from '@shell/config/types';
import lodashMap from 'lodash/map';
import { datasetApi } from './common/api'
import { MinioDownload } from '@shell/utils/minio';

export default {
    name: 'CommonAdmin',
    layout: 'home',
    components: {
        SortableTable, BadgeState, Masthead, CommonModal, commonDetailModal, DeleteModal
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        isHasHeader: {
            type: Boolean,
            default: true
        },
        url: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        headers: {
            type: Array,
            default: () => []
        },
        customRows: {
            type: Object,
            default: () => { }
        },
        tableAction: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            STATUS_CLASS_MAP,
            DATA_STATUS_MAP,
            DATA_TYPE_MAP,
            TASK_STATUS_MAP,
            rows: [],
            currentPage: 1,
            pageSize: 12,
            total: 0,
            modalTitle: '',
            inputval: '',
            selectedItem: null,
            mode: 'create',
            interval: null
        }
    },
    created() {
        this.fetchData()
        this.interval = setInterval(this.fetchData, 5000)
    },
    beforeDestroy() {
        this.interval && clearInterval(this.interval);
    },
    computed: {
        principal() {
            return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
        }
    },
    methods: {
        lodashMap,
        dayFormatByCommon,
        btnControl(row, type, params) {
            this[type](row, ...params)
        },
        showMenu(show, id) {
            if (this.$refs['popover' + id] && show) {
                this.$refs['popover' + id].show();
            } else {
                this.$refs['popover' + id].hide();
            }
        },
        addNewModal(row, mode) {
            this.mode = mode
            this.selectedItem = row
            this.modalTitle = mode === 'edit' ? '编辑' : '创建'
            this.$modal.show('commonmodal')
        },
        downFile(row) {
            MinioDownload(row?.tarOutMinio)
        },
        queryDetail(row, mode) {
            this.mode = mode
            this.selectedItem = row
            console.log(1)
            this.$modal.show('commonDetailModal')
        },
        pageChange(page) {
            this.currentPage = page
        },
        fetchData() {
            const url = this.inputval === '' ? this.url + '/?createUser=' + this.principal.loginName + '&page=' + this.currentPage + '&size=' + this.pageSize : this.url + '/?createUser=' + this.principal.loginName + '&name=' + this.inputval + '&page=' + this.currentPage + '&size=' + this.pageSize
            this.$axios.get(url).then(res => {
                this.total = res.data.data.total
                this.rows = res.data.data.records
            })
        },
        delItemById(row) {
            this.selectedItem = row
            this.$refs.deleteItemRef.show()
        },
        async deleteAItem() {
            const url = this.url + '/' + this.selectedItem.id
            try {
                const response = await this.$axios.delete(url);
                if (response?.status === 200 && response?.data.rtnMessage === 'success') {
                    this.fetchData();
                    this.$refs.deleteItemRef.close()
                    this.$store.dispatch('growl/success', { title: '提示', message: '删除成功！', timeout: 1000 }, { root: true });
                    this.selectedItemId = null
                }else {
                    this.$refs.deleteItemRef.close()
                    this.$store.dispatch('growl/error', { title: '提示', message: response?.data.rtnMessage, timeout: 1000 }, { root: true });
                    this.selectedItemId = null
                }
            } catch (e) {
                this.$refs.deleteItemRef.close()
                this.$store.dispatch('growl/error', { title: '提示', message: response?.data.rtnMessage, timeout: 1000 }, { root: true });
                this.selectedItemId = null
             }
        },
        finishModal(val) {
            this.$modal.hide('commonmodal')
            if (val === 0) {
                this.fetchData()
            }
        },
        async startTrain(row) {
            if (!['imported', 'error','new'].includes(row.status)) {
                this.$store.dispatch('growl/warning', { title: '提示', message: '此任务在训练中！', timeout: 1000 }, { root: true });
                return
            } else {
                this.$axios.post(datasetApi.starttraintask, row).then(res => {
                    if (res && res.data.rtnCode === 0) {
                        this.$store.dispatch('growl/success', { title: '提示', message: '开始训练!', timeout: 1000 }, { root: true });
                    } else {
                        this.$store.dispatch('growl/error', { title: '提示', message: res.data.rtnMessage, timeout: 1000 }, { root: true });
                    }
                }).catch(() => {
                    this.$store.dispatch('growl/error', { title: '提示', message: '训练失败!', timeout: 1000 }, { root: true });
                })
            }
        },
        async exportTask(row) {
            if (!['imported'].includes(row.status)) {
                this.$store.dispatch('growl/warning', { title: '提示', message: '此任务当前状态不能导出！', timeout: 1000 }, { root: true });
                return
            } else {
                this.$axios.post(datasetApi.createllodelFiles + row.id).then(res => {
                    if (res && res.data.rtnCode === 0) {
                        this.$store.dispatch('growl/success', { title: '提示', message: '导出成功, 模型文件模块查看详情!', timeout: 1000 }, { root: true });
                    } else {
                        this.$store.dispatch('growl/error', { title: '提示', message: res.data.rtnMessage, timeout: 1000 }, { root: true });
                    }
                }).catch(() => {
                    this.$store.dispatch('growl/error', { title: '提示', message: '导出失败!', timeout: 1000 }, { root: true });
                })
            }
        }
    }
}
</script>
  
<style lang='scss' scoped>
.commonadmin-page {
    background-color: #ffffff;
    height: 100%;
    overflow: hidden;

    .title {
        h1 {
            font-size: 18px;
            font-weight: bold;
            font-family: Microsoft Yahei;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .search {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                width: 280px;
                height: 32px;
                opacity: 1;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0px;
                padding: 6px 8px 6px 8px;
                margin-right: 6px;
            }
        }
    }

    .contentbox {
        height: calc(100% - 162px);
        overflow: auto;

        &::-webkit-scrollbar {
            width: 3px !important;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(238, 238, 238, 1) !important;
            border-radius: 2.5px !important;
        }
    }
}

::v-deep {
    .btnactions {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 0px;

        .moreactionbtn {
            transform: rotateZ(90deg);
            margin-top: 8px;

            i {
                color: var(--lighterText);
            }
        }
    }
}

::v-deep {
    .services {
        .header {
            height: 20px;
            opacity: 1;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 20px;
            margin-bottom: 10px;
            color: var(--default);
            position: relative;
            text-align: center;

            i {
                position: absolute;
                right: 0px;
                top: 3px;
                font-size: 14px;
                color: var(--lighterText);
                cursor: pointer;
            }
        }

        .servicecontainer {
            width: 100%;
            height: auto;
            padding: 22px 0px 0px 0px;
        }
    }
}
</style>