<script>
import FileSelector from '@shell/components/form/FileSelector';
import { _EDIT, _VIEW } from '@shell/config/query-params';
import { NORMAN } from '@shell/config/types';
import Select from '@shell/components/form/Select';
import Error from '@shell/components/form/Error';
import AsyncButton from '@shell/components/AsyncButton';

export default {
  name: 'ImageEdit',
  components: { Select, Error, FileSelector, AsyncButton },
  props: {
    mode: {
      type: String,
      default: _EDIT,
    },
    rules: {
      default: () => [],
      type: Array,
      // we only want functions in the rules array
      validator: (rules) => rules.every((rule) => ['function'].includes(typeof rule))
    }
  },

  data() {
    return {
      image: {
        name: '',
        info: '',
        version: '',
        imagename: ''
      },
      file: null
    }
  },
  computed: {
    isView() {
      return this.mode === _VIEW;
    },
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    },
  },

  created() {
  },

  mounted() {

  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    onKeySelected(data) {
      if (!data.file.name.endsWith('.tar.gz') && !data.file.name.endsWith('.docker.xz')) {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查文件格式' }, { root: true });
        return
      } else {
        this.file = data.file
        this.image.imagename = data.name
      }
    },
    async updateImage(buttonCb) {
      const url = '/apinew/image/addImage'
      const fd = new FormData()
      fd.append('file', this.file)
      fd.append('name', this.image.name)
      fd.append('version', this.image.version)
      fd.append('info', this.image.info)
      fd.append('imageName', this.image.imagename)
      fd.append('createUser', this.principal.loginName)
      try {
        const response = await this.$axios.post(url, fd, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response?.status === 200 && response?.data?.rtnMessage === 'success') {
          buttonCb(true);
          this.$store.dispatch('growl/success', { title: '提示', message: '上传成功！' }, { root: true });
          setTimeout(() => this.closeModal(), 500)
        }
      } catch (e) {
        buttonCb(false);
      }
    },
    save(buttonCb) {
      if (this.image.name === '' || this.image.version === '' || this.image.info === '' || this.image.imagename === '') {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
        return
      }
      this.updateImage(buttonCb)
    }
  },
};
</script>

<template>
  <div class="imageeditform">
    <div class="image-row">
      <div class="image-item">
        <span class="label">镜像名称: </span>
        <span v-if="isView">{{ image.name }}</span>
        <input v-else ref="image-name" v-model="image.name" type="text">
      </div>
      <div class="image-item">
        <span class="label">版本: </span>
        <span v-if="isView">{{ image.version }}</span>
        <input ref="version" v-model="image.version">
      </div>
    </div>
    <div class="image-row">
      <div class="image-item">
        <span class="label">描述: </span>
        <span v-if="isView">{{ image.info }}</span>
        <input v-else ref="image-usage" v-model="image.info" type="text">
      </div>
      <div class="image-item">
        <span class="label">上传镜像包: </span>
        <FileSelector style="background-color: transparent;borderColor: transparent;boxShadow: none;"
          :label.sync="image.imagename" :include-file-name="true" :byteLimit="Math.pow(1024, 3)" :closeIcon = "true" :accept="'.xz,.gz'"
          @selected="onKeySelected">
          <template #icon>
            <i class="icon icon-upload"></i>
          </template>
        </FileSelector>
      </div>
    </div>
    <Error :value="{ ...image }" :rules="rules" />
    <div class="footer">
      <button type="button" class="btn btn-border" @click="closeModal">
        取消
      </button>
      <AsyncButton id="imagesubmit" data-testid="image-update-submit" class="btn role-primary" type="submit"
        action-label="保存" waiting-label="上传中" success-label="上传成功" error-label="上传失败" @click="save" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.imageeditform {
  display: flex;
  flex-direction: column;
  gap: 38px;
  font-family: 'Microsoft Yahei';

  .image-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;

    .image-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;

      .label {
        width: 120px;
        height: 20px;
        opacity: 1;
        /** 文本1 */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 20px;
        color: var(--lightText);
        text-align: left;
      }

      input {
        width: 100%;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
      }

      .icon-upload {
        font-size: 24px;
        opacity: 1;
        color: rgba(23, 124, 255, 1);
        cursor: pointer;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
</style>
