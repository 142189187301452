<script>
import FileSelector from '@shell/components/form/FileSelector';
import Select from '@shell/components/form/Select';
import Error from '@shell/components/form/Error';
import AsyncButton from '@shell/components/AsyncButton';
import { NORMAN } from '@shell/config/types';

export default {
  name: 'AlgoEdit',
  components: { Select, Error, FileSelector, AsyncButton },
  props: {
    mode: {
      type: String,
      default: '_ADD',
    },
    imagerows: {
      default: () => [],
      type: Array,
    },
    allimages: {
      default: () => [],
      type: Array,
    },
    editObj: {
      default: () => { },
      type: Object,
    },
    rules: {
      default: () => [],
      type: Array,
      // we only want functions in the rules array
      validator: (rules) => rules.every((rule) => ['function'].includes(typeof rule))
    }
  },

  data() {
    return {
      algo: {
        name: '',
        desc: '',
        usage: '',
        version: '',
        imageName: '',
        imageId: '',
        type: 0,
        file: null,
        algoImageName: '',
        compressedMinioAddress: ''
      },
      typerows: [
        { value: 1, name: "普通算法" },
        { value: 2, name: "训练算法" },
        { value: 3, name: "普通、训练算法" }
      ]

    }
  },

  computed: {
    isEdit() {
      return this.mode === '_EDIT';
    },
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    },
  },
  mounted() {
    if (this.mode === '_EDIT' || this.mode === '_REUPLOAD' && this.editObj) {
      this.algo.name = this.editObj.name
      this.algo.version = this.editObj.version
      this.algo.desc = this.editObj.desc
      this.algo.usage = this.editObj.usage
      this.algo.type = this.editObj.type
      this.algo.imageName = this.editObj.imageName
      this.algo.compressedMinioAddress = this.editObj.compressedMinioAddress
      const targetImage = this.allimages.find(item => item.imageName === this.algo.imageName)
      this.algo.imageName = this.mode === '_EDIT' ? this.editObj.imageName : targetImage?.name
    } else {
      this.algo.name = ''
      this.algo.desc = ''
      this.algo.usage = ''
      this.algo.version = ''
      this.algo.imageName = ''
      this.algo.imageId = ''
      this.algo.type = 0
      this.algo.file = null
      this.algo.algoImageName = ''
      this.algo.compressedMinioAddress = ''
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    onKeySelected(data) {
      if (!data.file.name.endsWith('.tar.gz') && !data.file.name.endsWith('.docker.xz')) {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查文件格式' }, { root: true });
        return
      } else {
        this.algo.file = data.file
        this.algo.algoImageName = data.name
      }
    },
    async addAlgo(buttonCb) {
      if (this.algo.name === '' || this.algo.version === '' || this.algo.desc === '' || this.algo.usage === '' || this.algo.type === 0 || this.algo.imageName === '' || this.algo.algoImageName === '') {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
        return
      }
      const targetImage = await this.allimages.find(item => item.name === this.algo.imageName)
      this.algo.imageId = targetImage?.id
      const imageName = targetImage?.imageName
      const url = '/apinew/algo/insertAlgo'
      const fd = new FormData()
      fd.append('file', this.algo.file)
      fd.append('name', this.algo.name)
      fd.append('version', this.algo.version)
      fd.append('usage', this.algo.usage)
      fd.append('type', this.algo.type)
      fd.append('desc', this.algo.desc)
      fd.append('imageName', imageName)
      fd.append('imageId', this.algo.imageId)
      fd.append('createUser', this.principal.loginName)
      if (this.mode === '_REUPLOAD') {
        fd.append('id', this.editObj.id)
      }
      try {
        const response = await this.$axios.post(url, fd, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response?.status === 200 && response?.data?.rtnMessage === 'success') {
          buttonCb(true);
          this.$emit('successSave')
          this.$store.dispatch('growl/success', { title: '提示', message: '上传成功！' }, { root: true });
          setTimeout(() => this.closeModal(), 500)
        } else {
          buttonCb('cancelled')
          this.$store.dispatch('growl/error', { title: '提示', message: response?.data?.rtnMessage }, { root: true });
          setTimeout(() => this.closeModal(), 500)
        }
      } catch (e) {
        buttonCb('cancelled')
        this.$store.dispatch('growl/error', { title: '提示', message: response?.data?.rtnMessage }, { root: true });
        setTimeout(() => this.closeModal(), 500)
      }
    },
    async updateAlgo(buttonCb) {
      if (this.algo.name === '' || this.algo.desc === '' || this.algo.usage === '' || this.algo.type === '') {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
        return
      }
      const url = '/apinew/algo/' + this.editObj.id
      const data = {
        "name": this.algo.name,
        "desc": this.algo.desc,
        "usage": this.algo.usage,
        "type": this.algo.type
      }
      const { name, desc, usage, ...rest } = this.editObj
      try {
        const response = await this.$axios.put(url, { ...rest, ...data });
        if (response?.status === 200 && response?.data?.rtnMessage === 'success') {
          buttonCb(true);
          this.$emit('successSave')
          this.$store.dispatch('growl/success', { title: '提示', message: '更新成功！' }, { root: true });
          setTimeout(() => this.closeModal(), 500)
        } else {
          buttonCb('cancelled')
          this.$store.dispatch('growl/error', { title: '提示', message: response?.data?.rtnMessage }, { root: true });
          setTimeout(() => this.closeModal(), 500)
        }
      } catch (e) {
        buttonCb(false);
      }
    },
    save(buttonCb) {
      this.mode === '_EDIT' ? this.updateAlgo(buttonCb) : this.addAlgo(buttonCb)
    }
  },
};
</script>

<template>
  <div class="algoeditform">
    <div class="algo-row">
      <div class="algo-item">
        <span class="label">算法名称: </span>
        <input ref="algo-name" v-model="algo.name" type="text">
      </div>
      <div class="algo-item">
        <span class="label">算法版本: </span>
        <input ref="version" v-model="algo.version" :disabled="mode === '_EDIT'">
      </div>
    </div>
    <div class="algo-row">
      <div class="algo-item">
        <span class="label">描述: </span>
        <input ref="version" v-model="algo.desc">
      </div>
      <div class="algo-item">
        <span class="label">用途: </span>
        <input v-model="algo.usage">
      </div>
    </div>
    <div class="algo-row">
      <div class="algo-item" v-if="mode === '_ADD' || mode === '_REUPLOAD'">
        <span class="label">算法类型: </span>
        <Select :options="typerows" v-model="algo.type">
          <template #option="option">
            {{ option.name }}
          </template>
          <template #selected-option="option">
            {{ option.name }}
          </template>
        </Select>
      </div>
      <div class="algo-item" v-if="mode === '_EDIT'">
        <span class="label">算法类型: </span>
        <Select :options="typerows" v-model="algo.type" disabled>
          <template #option="option">
            {{ option.name }}
          </template>
          <template #selected-option="option">
            {{ option.name }}
          </template>
        </Select>
      </div>
      <div class="algo-item" v-if="mode === '_ADD' || mode === '_REUPLOAD'">
        <span class="label">选择镜像: </span>
        <Select :options="imagerows" v-model="algo.imageName" />
      </div>
      <div class="algo-item" v-if="mode === '_EDIT'">
        <span class="label">镜像: </span>
        <input v-model="algo.imageName" disabled>
      </div>
    </div>
    <div class="algo-row">
      <div class="algo-item" v-if="mode === '_ADD' || mode === '_REUPLOAD'">
        <span class="label">上传算法包: </span>
        <FileSelector style="background-color: transparent;borderColor: transparent;boxShadow: none;"
          :label.sync="algo.algoImageName" :include-file-name="true" :accept="'.xz,.gz'" :byteLimit="Math.pow(1024, 3)"
          :closeIcon = "true"
          @selected="onKeySelected">
          <template #icon>
            <i class="icon icon-upload"></i>
          </template>
        </FileSelector>
      </div>
      <div class="algo-item" v-if="mode === '_EDIT'">
        <span class="label">算法镜像地址: </span>
        <i class="icon icon-error" v-tooltip="algo.compressedMinioAddress"></i>
      </div>
    </div>
    <Error :value="{ ...algo }" :rules="rules" />
    <div class="footer">
      <button type="button" class="btn btn-border" @click="closeModal">
        取消
      </button>
      <AsyncButton id="algosubmit" data-testid="algo-update-submit" class="btn role-primary" type="submit"
        :action-label="editObj?.status === 20 ? '重新导入' : '保存'" waiting-label="上传中" success-label="上传成功" error-label="上传失败"
        @click="save" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.algoeditform {
  display: flex;
  flex-direction: column;
  gap: 38px;
  font-family: 'Microsoft Yahei';

  .algo-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;

    .algo-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;

      .label {
        width: 120px;
        height: 20px;
        opacity: 1;
        /** 文本1 */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 20px;
        color: var(--lightText);
        text-align: left;
      }

      input {
        width: 100%;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
      }

      .icon-upload {
        font-size: 24px;
        opacity: 1;
        color: rgba(23, 124, 255, 1);
        cursor: pointer;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
</style>
