<script>
import { KEYMAP } from '@shell/store/prefs';
import { _EDIT, _VIEW } from '@shell/config/query-params';
import Collapse from '@shell/components/Collapse';
import Select from '@shell/components/form/Select';

export default {
  name: 'CodeMirrorWithTitle',

  props: {
    /**
     * Sets the edit mode for Text Area.
     * @values _EDIT, _VIEW
     */
    mode: {
      type: String,
      default: _EDIT
    },
    options: {
      type: Object,
      default: () => { }
    },
    asTextArea: {
      type: Boolean,
      default: false
    },
    code: {
      type: Object,
      default: () => { }
    }
  },

  components: {
    Collapse, Select
  },

  data() {
    return {
      codeMirrorRef: null,
      loaded: false,
      open: true,
    };
  },

  computed: {
    isDisabled() {
      return this.mode === _VIEW;
    },
    combinedOptions() {
      const theme = this.$store.getters['prefs/theme'];
      const keymap = this.$store.getters['prefs/get'](KEYMAP);
      const out = {
        // codemirror default options
        tabSize: 2,
        indentWithTabs: false,
        mode: 'yaml',
        keyMap: keymap,
        theme: `base16-${theme}`,
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        styleSelectedText: true,
        showCursorWhenSelecting: true,
      };
      if (this.asTextArea) {
        out.lineNumbers = false;
        out.tabSize = 0;
        out.extraKeys = { Tab: false };
      }
      Object.assign(out, this.options);
      return out;
    },
  },

  created() {
    if (window.__codeMirrorLoader) {
      window.__codeMirrorLoader().then(() => {
        this.loaded = true;
      });
    } else {
      console.error('Code mirror loader not available'); // eslint-disable-line no-console
    }
  },

  methods: {

    focus() {
      if (this.$refs.codeMirrorRef) {
        this.$refs.codeMirrorRef.codemirror.focus();
      }
    },

    refresh() {
      if (this.$refs.codeMirrorRef) {
        this.$refs.codeMirrorRef.refresh();
      }
    },

    onReady(codeMirrorRef) {
      this.$nextTick(() => {
        codeMirrorRef.refresh();
        this.codeMirrorRef = codeMirrorRef;
      });
      this.$emit('onReady', codeMirrorRef);
    },

    onInput(newCode) {
      this.$emit('onInput', newCode);
    },

    onChanges(codeMirrorRef, changes) {
      this.$emit('onChanges', codeMirrorRef, changes);
    },

    onFocus() {
      this.$emit('onFocus', true);
    },

    onBlur() {
      this.$emit('onFocus', false);
    },

    updateValue(value) {
      if (this.$refs.codeMirrorRef) {
        this.$refs.codeMirrorRef.codemirror.doc.setValue(value);
      }
    }
  }
};
</script>

<template>
  <Collapse class="codeMirrorWithTitle" :open="open" @update:open="(val) => open = val">
    <template #title>
      <div class="trainparamstitle" v-if='!isDisabled'>
        <input class="trainparamstitleinput" :value="code?.title" @input="$emit('onTitleInput', $event.target.value)"
          placeholder="请输入参数配置文件名">
        <Select style="width: 12%;" :options="[{ label: 'yaml', value: 'yaml' }]" :value="code?.format"
          placeholder="请选择文件格式文件" />
      </div>
      <div class="trainparamstitle" v-else>
        <span style="margin:auto 0"> {{code?.title}}.{{code?.format}} </span>
      </div>
    </template>
    <div class="code-mirror" :class="{ ['as-text-area']: asTextArea }">
      <codemirror v-if="loaded" ref="codeMirrorRef" :value="code?.content" :options="combinedOptions"
        :disabled="isDisabled" @ready="onReady" @input="onInput" @changes="onChanges" @focus="onFocus" @blur="onBlur" />
      <div v-else>
        加载中...
      </div>
    </div>
  </Collapse>
</template>

<style lang="scss">
.code-mirror {
  z-index: 0;

  .vue-codemirror .CodeMirror {
    height: 200px !important;
    background: none
  }

  &.as-text-area {
    min-height: 40px;
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background-color: var(--input-bg);
    border-radius: var(--border-radius);
    border: solid var(--border-width) var(--input-border);
    color: var(--input-text);

    &:hover {
      border-color: var(--input-hover-border);
    }

    &:focus,
    &.focus {
      outline: none;
      border-color: var(--outline);
    }

    .CodeMirror-wrap pre {
      word-break: break-word;
    }

    .CodeMirror-code {
      .CodeMirror-line {

        &:not(:last-child)>span:after,
        .cm-markdown-single-trailing-space-odd:before,
        .cm-markdown-single-trailing-space-even:before {
          color: var(--muted);
          position: absolute;
          line-height: 20px;
          pointer-events: none;
        }

        &:not(:last-child)>span:after {
          content: '↵';
          margin-left: 2px;
        }

        .cm-markdown-single-trailing-space-odd:before,
        .cm-markdown-single-trailing-space-even:before {
          font-weight: bold;
          content: '·';
        }
      }
    }

    .CodeMirror-lines {
      color: var(--input-text);
      padding: 0;

      .CodeMirror-line>span>span {
        &.cm-overlay {
          font-family: monospace;
        }
      }

      .CodeMirror-line>span {
        font-family: $body-font;
      }
    }

    .CodeMirror-sizer {
      min-height: 20px;
    }

    .CodeMirror-selected {
      background-color: var(--primary) !important;
    }

    .CodeMirror-selectedtext {
      color: var(--primary-text);
    }

    .CodeMirror-line::selection,
    .CodeMirror-line>span::selection,
    .CodeMirror-line>span>span::selection {
      color: var(--primary-text);
      background-color: var(--primary);
    }

    .CodeMirror-line::-moz-selection,
    .CodeMirror-line>span::-moz-selection,
    .CodeMirror-line>span>span::-moz-selection {
      color: var(--primary-text);
      background-color: var(--primary);
    }
  }

}

.CodeMirror-line {
  text-align: left !important;
}

.codeMirrorWithTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // nav style
  .trainparamstitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 25px);

    .trainparamstitleinput {
      width: 70%;
      border: none;
    }

    .unlabeled-select:not(.view):not(.disabled),
    .unlabeled-select:not(.view):not(.disabled):hover:not(.focused):not(.disabled),
    .unlabeled-select:not(.view):not(.disabled):focus-visible:not(.focused):not(.disabled),
    .unlabeled-select:not(.view):not(.disabled):focus-within:not(.focused):not(.disabled) {
      border: none !important;
      outline: none !important;
    }

  }
}
</style>
