export const datasetApi = {
    'dataset': '/apinew/dataset',
    'mysqlToMinio': '/apinew/dataset/mysqlToMinio',
    'urlDataToMinio': '/apinew/dataset/urlDataToMinio',
    'traindata': '/apinew/trainingds',
    'traintask': '/apinew/training_tasks',
    'starttraintask': '/apinew/training_tasks/startTrainingTasks',
    'model': '/apinew/model_files',
    'createllodelFiles':"/apinew/model_files/createModelFiles/",
    'algo': '/apinew/algo'
}