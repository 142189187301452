<template>
  <div class="publish-page">
    <Masthead resource='servicepublish'>
      <template slot="extraActions">
        <button type="button" class="btn ml-6 role-primary" @click="addNewPublish"><i class="icon icon-plus" />
          新增服务发布
        </button>
      </template>
    </Masthead>
    <div class="actions">
      <div class="search">
        <input placeholder="请输入服务名称" />
        <button type="button" class="btn ml-6 role-primary"><i class="icon icon-search" />查询</button>
      </div>
    </div>
    <div class="publishsbox">
      <SortableTable :headers="headers" :rows="rows" key-field="id" :search="false" :paging="true" :compatible="false"
        :total="10" :rows-per-page="pageSize" :currentPage="currentPage" :row-actions="false" :table-actions="false"
        @pageChange="pageChange">

        <template #cell:status="{ row }">
          <BadgeState
            :label="row.state === 'active' ? '启用中' : row.state === 'bound' ? '绑定中' : row.state === 'running' ? '运行中' : row.state === 'completed' ? '已完成' : row.state === 'error' ? '错误' : row.state === 'errimageneverpull' ? '镜像拉取失败' : row.state"
            :color="STATUS_CLASS_MAP[row.state === 'active' ? 'success' : 'warning'].color" />
        </template>
        <template #cell:url="{ row }">
          <a :href="row.url" target="_blank">
            {{ row.url }}
          </a>
        </template>
        <template #cell:file="{ row }">
          <button type="button" class="btn btn-sm role-copy-action" @click="downloadFile(row)">
            <i class=" icon icon-download" v-tooltip="'下载说明文档'" />
          </button>
        </template>
        <template #cell:btnactions="{ row }">
          <div class="btnactions">
            <button type="button" class="btn btn-sm role-copy-action" @click="cancelPublish(row)">
              <i class="icon icon-delete" />&nbsp;&nbsp;取消发布
            </button>
          </div>
        </template>
      </SortableTable>
    </div>
    <!-- 取消发布弹框 -->
    <DeleteModal ref="cancelpublish" label="确定取消发布?" @disable="confirmCancel" />
    <!-- 新增服务发布 -->
    <modal name="pushmodal" width="50%" :click-to-close="false" :height="'auto'" class="modal" data-testid="mvc__card">
      <div class="services">
        <div class="header">
          新增服务发布<i class="icon icon-close" @click="$modal.hide('pushmodal')"></i>
        </div>
        <div class="servicecontainer">
          <ServiceModal mode="create" :allAlgos="allalgos" @close="$modal.hide('pushmodal')"
            @successPublsh="successPublsh" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import day from 'dayjs';
import SortableTable from '@shell/components/SortableTable';
import { BadgeState } from '@components/BadgeState';
import DeleteModal from '@shell/components/DeleteModal';
import ServiceModal from '@shell/components/service/ServiceModal.vue';
import Masthead from '@shell/components/ResourceList/Masthead';
import { MinioDownload } from '@shell/utils/minio';
import {
  STATUS_CLASS_MAP
} from '@shell/config/mapConfig'

export default {
  name: 'ServicePublish',
  layout: 'home',
  components: {
    SortableTable, BadgeState, ServiceModal, DeleteModal, Masthead
  },
  data() {
    return {
      STATUS_CLASS_MAP: STATUS_CLASS_MAP,
      headers: [
        { name: 'status', label: '状态' },
        { name: 'name', label: '发布描述' },
        { name: 'descChar', label: '发布关键字' },
        { name: 'url', label: '发布地址' },
        { name: 'version', label: '发布版本' },
        { name: 'file', label: '说明文档' },
        { name: 'creationTime', label: '发布时间' },
        { name: 'btnactions', label: '操作' }
      ],
      rows: [],
      currentPage: 1,
      pageSize: 12,
      total: 0,
      selectedItemId: null,
      selectedServicePublishItem: null,
      allalgos: []
    }
  },
  created() {
    this.fetchdata()
    this.loadAlgos()
  },
  methods: {
    async loadAlgos() {
      const url = '/apinew/algo/?page=1&size=1000'
      try {
        const response = await this.$axios.get(url);
        if (response?.status === 200) {
          this.allalgos = response.data.data.records.filter(algo => algo.status === 10)
        }
      } catch (e) { }
    },
    handleIngressArr(arr) {
      if (arr.length === 0) {
        this.total = 0
        this.rows = []
        return
      }
      this.total = arr.length
      const allIngressByService = []
      arr.map(ele => {
        const elejson = {}
        elejson.id = ele.id
        elejson.uuid = ele.metadata.uid
        elejson.name = ele.metadata.annotations['field.cattle.io/description']
        elejson.state = ele.metadata.state.name
        elejson.descChar = ele.metadata.name
        elejson.version = ele.metadata.labels['app.kubernetes.io/version']
        elejson.file = ele.metadata.annotations['document_url']
        elejson.creationTime = day(ele.metadata.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
        elejson.url = 'http://' + ele.spec.rules[0].host + '/' + ele.metadata.labels['app.kubernetes.io/version']
        allIngressByService.push(elejson)
      })
      this.rows = allIngressByService
    },
    async fetchdata() {
      const ingresses = await this.$store.dispatch('management/findAll', {
        type: 'networking.k8s.io.ingress',
        namespace: 'ai-center',
        opt: { force: true }
      }, { force: true });
      const ingressByLabel = ingresses.filter(ingress => ingress.metadata.labels && ingress.metadata.labels['app.kubernetes.io/component'] === 'service')
      this.handleIngressArr(ingressByLabel.reverse())
    },
    addNewPublish() {
      this.$modal.show('pushmodal')
    },
    async confirmCancel() {
      const removeIngressUrl = '/v1/networking.k8s.io.ingresses/ai-center/' + this.selectedServicePublishItem.descChar
      const removeServiceUrl = '/v1/services/ai-center/' + this.selectedServicePublishItem.descChar
      const removeDeploymentsUrl = '/v1/apps.deployments/ai-center/' + this.selectedServicePublishItem.descChar
      Promise.all([
        this.$axios.delete(removeIngressUrl),
        this.$axios.delete(removeServiceUrl),
        this.$axios.delete(removeDeploymentsUrl)
      ])
        .then(() => {
          this.fetchdata()
          this.$store.dispatch('growl/success', {
            title: '提示',
            message: '取消发布成功！',
            timeout: 5000,
          }, { root: true });
          this.$refs.cancelpublish.close()
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },
    successPublsh() {
      this.fetchdata()
      this.$store.dispatch('growl/success', {
        title: '提示',
        message: '发布成功！',
        timeout: 5000,
      }, { root: true });
      this.$modal.hide('pushmodal')
    },
    async downloadFile(row) {
      if (row?.file) {
        MinioDownload(row?.file)
      } else {
        this.$store.dispatch('growl/warning', {
          title: '提示',
          message: '该服务暂时没有说明文档！',
          timeout: 5000,
        }, { root: true });
      }
    },
    showMenu(show, id) {
      if (this.$refs['popover' + id] && show) {
        this.$refs['popover' + id].show();
      } else {
        this.$refs['popover' + id].hide();
      }
    },
    pageChange(page) {
      this.currentPage = page
    },
    cancelPublish(obj) {
      this.$refs.cancelpublish.show()
      this.selectedServicePublishItem = obj
    }
  }
}
</script>

<style lang='scss' scoped>
.publish-page {
  background-color: #ffffff;
  margin: 16px;
  height: 100%;
  padding: 0 24px 24px 24px !important;
  overflow: hidden;

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .search {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        width: 280px;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        padding: 6px 8px 6px 8px;
        margin-right: 6px;
      }
    }
  }

  .publishsbox {
    height: calc(100% - 138px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(238, 238, 238, 1) !important;
      border-radius: 2.5px !important;
    }
  }
}

::v-deep {
  .btnactions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 0px;

    .moreactionbtn {
      transform: rotateZ(90deg);
      margin-top: 8px;

      i {
        color: var(--lighterText);
      }
    }
  }
}

::v-deep {
  .services {
    .header {
      height: 20px;
      opacity: 1;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 20px;
      margin-bottom: 10px;
      color: var(--default);
      position: relative;
      text-align: center;

      i {
        position: absolute;
        right: 0px;
        top: 3px;
        font-size: 14px;
        color: var(--lighterText);
        cursor: pointer;
      }
    }

    .servicecontainer {
      width: 100%;
      height: auto;
      padding: 22px 0px 0px 0px;
    }
  }
}
</style>

