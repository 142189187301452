<script>
export default {
  name: 'Collapse',
  props: {
    open: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: ''
    }
  },

  methods: {
    showAdvanced() {
      this.$emit('update:open', !this.open);
    }
  }
};
</script>

<template>
  <div class="collapse">
    <div class="collapsenav">
      <div class="advanced text-link" data-testid="collapse-div" @click="showAdvanced">
        <i v-if="open" class="icon icon-chevron-down" data-testid="collapse-icon-down" />
        <i v-else class="icon icon-chevron-right" data-testid="collapse-icon-right" />
        {{ title }}
      </div>
      <slot name="title">
      </slot>
    </div>
    <div v-if="open" class="content" data-testid="collapse-content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.advanced {
  user-select: none;
  padding: 0 5px;
  cursor: pointer;
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
}

.content {
  background: var(--nav-active);
  border-radius: 4px;
}

.collapse {
  .collapsenav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    ;
  }
}
</style>
