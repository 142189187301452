<template>
    <div class="common">
        <CommonAdmin title="模型文件" :headers="headers" :isHasHeader='false' :url="url" type="model"
            :tableAction="tableAction" />
    </div>
</template>
  
<script>
import CommonAdmin from '@shell/components/CommonAdmin'
import { datasetApi } from '@shell/components/common/api'
export default {
    name: 'Model',
    components: {
        CommonAdmin
    },
    data() {
        return {
            headers: [
                { name: 'name', label: '名称' },
                { name: 'createTime', label: '创建时间' },
                { name: 'btnactions', label: '操作' }
            ],
            url: datasetApi.model,
            tableAction: [
                { key: 'query', text: "详情", btnclass: 'multi', iconclass: 'error', clickEvent: 'queryDetail', eventParams: [] },
                { key: 'down', text: "下载", btnclass: 'copy', iconclass: 'download ', clickEvent: "downFile", eventParams: [] },
                { key: 'del', text: "删除", btnclass: 'delete', iconclass: 'delete', clickEvent: 'delItemById', eventParams: [] },
            ]
        }
    },
}
</script>
  