<script>
import { NORMAN } from '@shell/config/types';
import Select from '@shell/components/form/Select';
import Error from '@shell/components/form/Error';
import AsyncButton from '@shell/components/AsyncButton';
import UnitInput from '@shell/components/form/UnitInput';
import { CUSTOM_LABELS } from '@shell/config/custom';
import FileSelector from '@shell/components/form/FileSelector';
import {
  WORKLOAD_TYPES, SERVICE, INGRESS
} from '@shell/config/types';
import FormValidation from '@shell/mixins/form-validation';
import { MinioUpload } from '@shell/utils/minio';

const DEFAULT_DEPLOYMENT = {
  type: WORKLOAD_TYPES.DEPLOYMENT,
  metadata: {
    name: '',
    namespace: 'ai-center',
    labels: {}
  },
  spec: {
    replicas: 1,
    selector: { matchLabels: {} },
    progressDeadlineSeconds: 600,
    revisionHistoryLimit: 1,
    minReadySeconds: 30,
    template: {
      metadata: {
        namespace: 'ai-center',
        labels: {}
      },
      spec: {
        containers: [
          {
            image: '',
            imagePullPolicy: 'Always',
            name: '',
            resources: {
              limits: {
                cpu: '1000m',
                memory: '128Mi',
              },
              requests: {
                cpu: '900m',
                memory: '100Mi'
              }
            },
            command: [],
            ports: [
              {
                "containerPort": 8080,
                "name": "http",
                "protocol": "TCP"
              }
            ]
          }
        ],
        imagePullSecrets: [],
        initContainers: [],
        restartPolicy: "Always",
        volumes: []
      }
    }
  }
}

const DEFAULT_SERVICE = {
  type: SERVICE,
  metadata: {
    name: '',
    namespace: 'ai-center',
    labels: {}
  },
  spec: {
    ports: [
      {
        name: 'http',
        port: 80,
        protocol: 'TCP',
        targetPort: 'http'
      }
    ],
    selector: { [CUSTOM_LABELS.COMPONENT]: 'service', [CUSTOM_LABELS.NAME]: '', [CUSTOM_LABELS.VERSION]: '', [CUSTOM_LABELS.PARTOF]: 'ai-center' },
    type: 'ClusterIP'
  }
};

const DEFAULT_INGRESS = {
  type: INGRESS,
  metadata: {
    name: '',
    namespace: 'ai-center',
    labels: { [CUSTOM_LABELS.COMPONENT]: 'service', [CUSTOM_LABELS.NAME]: '', [CUSTOM_LABELS.VERSION]: '', [CUSTOM_LABELS.PARTOF]: 'ai-center' },
    annotations: {
      "nginx.ingress.kubernetes.io/rewrite-target": "/$2",
      "document_url": "",
      'field.cattle.io/description': ''
    }
  },
  spec: {
    rules: [
      {
        host: '',
        http: {
          paths: [
            {
              backend: {
                service: {
                  name: '',
                  port: {
                    number: 80
                  }
                }
              },
              path: '',
              pathType: "Prefix"
            }
          ]
        }
      }
    ]
  }
};
const deploymentValues = structuredClone(DEFAULT_DEPLOYMENT);
const serviceValues = structuredClone(DEFAULT_SERVICE);
const ingressValues = structuredClone(DEFAULT_INGRESS);
export default {
  name: 'ServiceModal',
  components: { Select, Error, AsyncButton, UnitInput, FileSelector },
  mixins: [FormValidation],
  props: {
    algo: {
      type: Object,
      default: () => { }
    },
    mode: {
      type: String,
      default: ''
    },
    allAlgos: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      fvFormRuleSets: [{
        path: 'publishform', rules: ['Publish']
      }],
      deploymentValues,
      serviceValues,
      ingressValues,
      imagePullSecrets: [],
      extensionDeployment: null,
      extensionUrl: null,
      extensionSvc: null,
      publish: {
        name: '',
        version: '',
        desc: '',
        filename: '',
        file: null,
        requestsCpu: '900m',
        requestsMemory: '100Mi',
        limitsCpu: '1000m',
        limitsMemory: '128Mi',
        url: '',
      },
      resultview: false,
      targetAlgo: ''
    }
  },

  computed: {
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    },
    imageRegistry() {
      const Image_Registry = '192.168.2.171:15000/'
      return Image_Registry
    },
    namespacedDeployments() {
      return this.$store.getters['management/all'](WORKLOAD_TYPES.DEPLOYMENT).filter((dep) => dep.metadata.namespace === 'ai-center');
    },
    namespacedServices() {
      return this.$store.getters['management/all'](SERVICE).filter((svc) => svc.metadata.namespace === 'ai-center');
    },
    namespacedIngress() {
      return this.$store.getters['management/all'](INGRESS).filter((svc) => svc.metadata.namespace === 'ai-center');
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    assignLabels(source, labels, args) {
      for (let i = 0; i < args.length; i++) {
        const path = args[i].split('.');
        let currentObj = source;

        for (let j = 0; j < path.length - 1; j++) {
          currentObj = currentObj[path[j]];
        }

        currentObj[path[path.length - 1]] = labels;
      }

      return source;
    },
    parseDeploymentValues(image, name, imageType, version) {
      let out = {};
      const resources = {
        limits: {
          cpu: this.publish.limitsCpu,
          memory: this.publish.limitsMemory,
        },
        requests: {
          cpu: this.publish.requestsCpu,
          memory: this.publish.requestsMemory
        }
      }
      this.$set(this.deploymentValues.metadata, 'name', name);
      this.$set(this.deploymentValues.spec.template.spec.containers[0], 'image', image);
      this.$set(this.deploymentValues.spec.template.spec.containers[0], 'name', 'container' + Math.floor(Math.random() * 100) + 1);
      this.$set(this.deploymentValues.spec.template.spec.containers[0], 'command', imageType === 1 ? ['./run.sh'] : ['./train.sh']);
      this.$set(this.deploymentValues.spec.template.spec.containers[0], 'resources', resources);



      const addLabel = { [CUSTOM_LABELS.COMPONENT]: 'service', [CUSTOM_LABELS.NAME]: name, [CUSTOM_LABELS.VERSION]: version, [CUSTOM_LABELS.PARTOF]: 'ai-center' };
      const addTo = ['metadata.labels', 'spec.selector.matchLabels', 'spec.template.metadata.labels'];

      // Populates workloadselector labels
      out = this.assignLabels(this.deploymentValues, addLabel, addTo);

      if (this.imagePullSecrets.length) {
        out.spec.template.spec.imagePullSecrets = this.imagePullSecrets.map((secret) => {
          return { name: secret };
        });
      }

      return out;
    },
    parseServiceValues(name, serviceName, version) {
      const out = this.serviceValues;

      out.metadata.name = serviceName;
      out.metadata.labels[CUSTOM_LABELS.COMPONENT] = 'service';
      out.metadata.labels[CUSTOM_LABELS.NAME] = name;
      out.metadata.labels[CUSTOM_LABELS.VERSION] = version;
      out.metadata.labels[CUSTOM_LABELS.PARTOF] = 'ai-center';

      out.spec.selector[CUSTOM_LABELS.COMPONENT] = 'service';
      out.spec.selector[CUSTOM_LABELS.NAME] = name;
      out.spec.selector[CUSTOM_LABELS.VERSION] = version;
      out.spec.selector[CUSTOM_LABELS.PARTOF] = 'ai-center';

      return out;
    },
    parseIngressValues(name, version, desc, fileUrl) {
      const out = this.ingressValues;
      out.metadata.name = name;
      out.spec.rules[0].host = this.publish.name + '.192.168.2.192.sslip.io';
      out.metadata.labels[CUSTOM_LABELS.COMPONENT] = 'service';
      out.metadata.labels[CUSTOM_LABELS.NAME] = name;
      out.metadata.labels[CUSTOM_LABELS.VERSION] = version;
      out.metadata.labels[CUSTOM_LABELS.PARTOF] = 'ai-center';
      out.metadata.annotations['field.cattle.io/description'] = desc;
      out.metadata.annotations['document_url'] = fileUrl;
      out.spec.rules[0].http.paths[0].backend.service.name = name;
      out.spec.rules[0].http.paths[0].path = `/${version}(/|$)(.*)`
      this.publish.url = 'http://' + this.publish.name + '.192.168.2.192.sslip.io/' + version

      return out;
    },
    // 加载deployment
    async loadDeployment(image, name, imageType, version, buttonCb) {
      const exists = this.namespacedDeployments.find((dep) => dep.spec.template.spec.containers[0].image === image);
      if (!exists) {
        const deploymentValues = this.parseDeploymentValues(image, name, imageType, version);
        this.extensionDeployment = await this.$store.dispatch('management/create', deploymentValues);
        try {
          await this.extensionDeployment.save();
        } catch (e) {
          buttonCb(false)
          this.handleGrowlError(e, true);
        }
      } else {
        const error = {
          _statusText: this.t('plugins.manageCatalog.imageLoad.error.exists.deployment.title'),
          message: this.t('plugins.manageCatalog.imageLoad.error.exists.deployment.message', { image })
        };
        buttonCb(false)
        this.handleGrowlError(error);
      }
    },
    // 加载service
    async loadService(name, version, buttonCb) {
      const serviceName = name;
      const exists = this.namespacedServices.find((svc) => svc.metadata.name === serviceName);

      if (exists) {
        const error = {
          _statusText: this.t('plugins.manageCatalog.imageLoad.error.exists.service.title'),
          message: this.t('plugins.manageCatalog.imageLoad.error.exists.service.message', { svc: serviceName })
        };
        buttonCb(false)
        this.handleGrowlError(error, true);
        return;
      }

      // Sets serviceValues with name, label, and selector
      const serviceValues = this.parseServiceValues(name, serviceName, version);
      const serviceResource = await this.$store.dispatch('management/create', serviceValues);

      try {
        await serviceResource.save();
      } catch (e) {
        buttonCb(false)
        this.handleGrowlError(e, true);
        return;
      }

      try {
        this.extensionSvc = await this.$store.dispatch('management/find', {
          type: SERVICE,
          id: `ai-center/${serviceResource.metadata.name}`,
          namespace: 'ai-center'
        });

        if (this.extensionSvc) {
          this.extensionUrl = `http://${this.extensionSvc.spec.clusterIP}:${this.extensionSvc.spec.ports[0].port}`;
        } else {
          buttonCb(false)
          throw new Error('Error fetching extension service');
        }
      } catch (e) {
        buttonCb(false)
        this.handleGrowlError(e, true);
      }
    },
    // 加载ingress
    async loadIngress(name, version, desc, fileUrl, buttonCb) {
      const exists = this.namespacedIngress.find((svc) => svc.metadata.name === name);

      if (exists) {
        const error = {
          _statusText: this.t('plugins.manageCatalog.imageLoad.error.exists.service.title'),
          message: '服务已存在'
        };
        buttonCb(false)
        this.handleGrowlError(error, true);
        return;
      }


      const ingressValues = this.parseIngressValues(name, version, desc, fileUrl);
      const ingressResource = await this.$store.dispatch('management/create', ingressValues);

      try {
        await ingressResource.save();
      } catch (e) {
        buttonCb(false)
        this.handleGrowlError(e, true);
        return;
      }
    },
    async addService(buttonCb) {
      if (this.publish.name === '' || this.publish.version === '' || this.publish.desc === '' || (this.mode === 'create' && this.targetAlgo === '') || !/^(?=.*[a-z])[a-z0-9-]+$/.test(this.publish.name) || !/^v\d+$/.test(this.publish.version)) {
        this.$store.dispatch('growl/warning', { title: '提示', message: '请检查表单选项' }, { root: true });
        buttonCb(false)
        return
      }
      const { status, content } = await MinioUpload(this.publish.file, this.$store)
      const fileUrl = content.data
      if (status && content.rtnCode === 0) {
        const name = await this.publish.name + '-' + Math.floor(Math.random() * 100) + 1
        const image = (this.mode === 'create' && this.targetAlgo !== '') ? this.imageRegistry + this.targetAlgo?.targetImageName : this.imageRegistry + this.algo?.targetImageName
        const imageType = (this.mode === 'create' && this.targetAlgo !== '') ? this.targetAlgo?.type : this.algo?.type
        await this.loadDeployment(image, name, imageType, this.publish.version, buttonCb)
        if (this.extensionDeployment) {
          // Create deploy
          await this.loadService(name, this.publish.version, buttonCb);
        }
        if (this.extensionSvc) {
          // Create ingress
          await this.loadIngress(name, this.publish.version, this.publish.desc, fileUrl, buttonCb);
          await buttonCb(true)
          if (this.mode === 'create') {
            this.$emit('successPublsh')
          } else {
            this.resultview = true
          }

        }
      }

    },
    clean() {
      // Remove failed resources
      if (this.extensionDeployment) {
        this.extensionDeployment.remove();
      }
      if (this.extensionSvc) {
        this.extensionSvc.remove();
      }
    },
    handleGrowlError(e, clean = false) {
      const error = e?.data || e;

      this.$store.dispatch('growl/error', {
        title: error._statusText,
        message: error.message,
        timeout: 5000,
      }, { root: true });

      if (clean) {
        this.clean();
      }
    },
    onKeySelected(data) {
      this.publish.file = data.file
      this.publish.filename = data.name
    }
  },
};
</script>

<template>
  <div class="ServiceModalForm">
    <template v-if="!resultview">
      <div class="image-row">
        <div class="image-item">
          <span class="label">发布名称: </span>
          <input v-model="publish.desc" type="text">
        </div>
        <div class="image-item">
          <span class="label">版本: </span>
          <input v-model="publish.version">
        </div>
      </div>
      <div class="image-row" v-if="mode === 'create'">
        <div class="image-item">
          <span class="label">发布命名: </span>
          <input v-model="publish.name" type="text">
        </div>
        <div class="image-item">
          <span class="label">发布算法: </span>
          <Select :options="allAlgos" v-model="targetAlgo">
            <template #option="option">
              {{ option.name }}
            </template>
            <template #selected-option="option">
              {{ option.name }}
            </template>
          </Select>
        </div>
      </div>
      <div class="image-row" v-if="mode !== 'create'">
        <div class="image-item">
          <span class="label">发布命名: </span>
          <input v-model="publish.name" type="text">
        </div>
        <div class="image-item">
          <span class="label">说明文档: </span>
          <FileSelector style="background-color: transparent;borderColor: transparent;boxShadow: none;"
            :label.sync="publish.filename" :include-file-name="true" :byteLimit="Math.pow(1024, 3)" :closeIcon="true"
            @selected="onKeySelected">
            <template #icon>
              <i class="icon icon-upload"></i>
            </template>
          </FileSelector>
        </div>
      </div>
      <div class="image-row" v-if="mode === 'create'">
        <div class="image-item">
          <span class="label">说明文档: </span>
          <FileSelector style="background-color: transparent;borderColor: transparent;boxShadow: none;"
            :label.sync="publish.filename" :include-file-name="true" :byteLimit="Math.pow(1024, 3)" :closeIcon="true"
            @selected="onKeySelected">
            <template #icon>
              <i class="icon icon-upload"></i>
            </template>
          </FileSelector>
        </div>
      </div>
      <div class="image-row">
        <div class="image-item resources">
          <div class="header">
            <span>资源配置</span>
          </div>
          <div class="resources_inputs">
            <div class="row mb-20">
              <span class="col span-6">
                <UnitInput v-model="publish.requestsCpu" :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                  :label="t('containerResourceLimit.requestsCpu')" mode="create" :input-exponent="-1"
                  :output-modifier="true" :base-unit="t('suffix.cpus')" data-testid="cpu-reservation" />
              </span>
              <span class="col span-6">
                <UnitInput v-model="publish.requestsMemory" :placeholder="t('containerResourceLimit.memPlaceholder')"
                  :label="t('containerResourceLimit.requestsMemory')" mode="create" :input-exponent="2" :increment="1024"
                  :output-modifier="true" data-testid="memory-reservation" />
              </span>
            </div>

            <div class="row mb-20">
              <span class="col span-6">
                <UnitInput v-model="publish.limitsCpu" :placeholder="t('containerResourceLimit.cpuPlaceholder')"
                  :label="t('containerResourceLimit.limitsCpu')" mode="create" :input-exponent="-1"
                  :output-modifier="true" :base-unit="t('suffix.cpus')" data-testid="cpu-limit" />
              </span>
              <span class="col span-6">
                <UnitInput v-model="publish.limitsMemory" :placeholder="t('containerResourceLimit.memPlaceholder')"
                  :label="t('containerResourceLimit.limitsMemory')" mode="create" :input-exponent="2" :increment="1024"
                  :output-modifier="true" data-testid="memory-limit" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Error :value="{ ...publish }" :rules="fvGetAndReportPathRules('publishform')" />
      <div class="footer">
        <button type="button" class="btn btn-border" @click="closeModal">
          取消
        </button>
        <AsyncButton id="servicesubmit" data-testid="service-update-submit" class="btn role-primary" type="submit"
          action-label="发布" waiting-label="发布中" success-label="发布成功" error-label="发布失败" @click="addService" />
      </div>
    </template>
    <template v-else>
      <div class="publishpreview">
        <div class="publishurl"> <i class="icon icon-checkmark" /></div>
        <div class="publishText">
          <h3> 服务地址: <a :href="publish.url" target="_blank">{{ publish.url }}</a> </h3>
          <span>服务详情可去服务管理-服务发布模块查看</span>
        </div>

      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.ServiceModalForm {
  display: flex;
  flex-direction: column;
  gap: 38px;
  font-family: 'Microsoft Yahei';

  .image-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;

    .image-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;

      .label {
        min-width: 100px;
        height: 20px;
        opacity: 1;
        /** 文本1 */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 20px;
        color: var(--lightText);
        text-align: left;
      }

      input {
        width: 100%;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
      }

      .icon-upload {
        font-size: 24px;
        opacity: 1;
        color: rgba(23, 124, 255, 1);
        cursor: pointer;
      }
    }

    .resources {
      border-radius: 6px;
      border: 1.5px solid var(--border);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .header {
        width: 108px;
        height: 38px;
        opacity: 1;
        border-radius: 6px;
        background: rgba(237, 245, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 86.8px;
          height: 26.13px;
          opacity: 1;
          border-radius: 6px;
          background: #ffffff;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0px;
          line-height: 20px;
          color: var(--primary);
          display: block;
          line-height: 26.13px;
        }
      }

      .resources_inputs {
        width: 100%;
        padding: 0 40px;
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .publishpreview {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .publishurl {
      background-color: var(--success);
      display: block;
      width: 100px;
      height: 100px;
      color: #ffffff;
      font-size: 64px;
      border-radius: 50%;
      line-height: 108px;
    }

    .publishText {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h3 {
        margin-bottom: 0;
        font-size: 24px;
      }

      span {
        color: var(--lighterText);
        text-align: left;
      }
    }

  }
}

::v-deep {
  .inputbox {
    label {
      width: 80px;
    }
  }
}
</style>
