<script>
import imageitem from '../../../../../components/images/imageitem.vue'
import imageedit from '../../../../../components/images/imageedit.vue'
import DeleteModal from '@shell/components/DeleteModal';
import CustomPagination from '@shell/components/SortableTable/custompagination.vue';
import { Card } from '@components/Card';
import FormValidation from '@shell/mixins/form-validation';
import Masthead from '@shell/components/ResourceList/Masthead';
import dayjs from 'dayjs'

export default {
    name:'Images',
    layout: 'home',
    mixins: [FormValidation],
    components:{
      imageitem,Card,imageedit,DeleteModal,CustomPagination,Masthead
    },
    data(){
      return {
        fvFormRuleSets: [{
           path: 'imageform', rules: ['Image']
        }],
        interval:null,
        rows:[],
        selectedItemId:null,
        imageName:'',
        imageStatus:'',
        currentPage:1,
        pageSize:12,
        total:0,
      }
    },
    created() {
      this.fetchData();
      this.interval = setInterval(this.fetchData, 5000); 
    },
    beforeDestroy() {
      this.interval && clearInterval(this.interval);
    },
    methods:{
      addimage(){
        this.$modal.show('imagemodal')
      },
      fetchDataByStatus(status){
        if(this.imageStatus!==0 && this.imageStatus === status) {
          this.imageStatus = ''
        }else {
          this.imageStatus = status
        }
        this.fetchData()
      },
      deleteImage(val){
        this.$refs.deleteImage.show()
        this.selectedItemId = val
      },
      async deleteAImage(){
        const url = '/apinew/image/'+ this.selectedItemId
        try {
          const response = await this.$axios.delete(url);
          if (response?.status === 200 && response?.data.rtnMessage === 'success') {
            this.fetchData();
            this.$refs.deleteImage.close()
            this.$store.dispatch('growl/success', { title: '提示',message:'删除成功！' }, { root: true });
            this.selectedItemId = null
          }
        } catch (e) {}
      },
      close(){
        this.$modal.hide('imagemodal')
      },
      dayFormat(time){
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss')
      },
      pageChange(page){
        this.currentPage = page
        this.fetchData()
      },
      async fetchData(){
        const url = (this.imageName!== '' && this.imageStatus === '') ? `/apinew/image/?name=${this.imageName}&page=${this.currentPage}&size=${this.pageSize}`:(this.imageName ===  '' && this.imageStatus !== '')?`/apinew/image/?status=${this.imageStatus}&page=${this.currentPage}&size=${this.pageSize}`:(this.imageName !== '' && this.imageStatus !== '')?`/apinew/image/?name=${this.imageName}&status=${this.imageStatus}&page=${this.currentPage}&size=${this.pageSize}`:`/apinew/image/?page=${this.currentPage}&size=${this.pageSize}`
        try {
          const response = await this.$axios.get(url);
          if (response?.status === 200 ) {
            this.total = response.data.data.total
            this.rows = response.data.data.records
          }
      } catch (e) {}
      }
    }
}
</script>

<template>
  <div class="images-page">
    <Masthead
      resource = 'images'
    >
      <template slot="extraActions">
        <button type="button" class="btn ml-6 role-primary" @click="addimage"><i class="icon icon-plus" />新增镜像</button>
      </template>
    </Masthead>
    <div class="actions">
      <div class="search">
        <input placeholder="请输入镜像名称" v-model="imageName"/>
        <button type="button" class="btn ml-6 role-primary" @click="fetchData"><i class="icon icon-search" />查询</button>
        <div class="imagesStatuLabels">
          <div class="status text-lighter" :class="{'active':imageStatus === '0'}" @click="fetchDataByStatus('0')">
            <span></span>
            制作中
          </div>
          <div class="status text-lighter" :class="{'active':imageStatus === '1'}"  @click="fetchDataByStatus('1')">
            <span></span>
            制作完成
          </div>
          <div class="status text-lighter" :class="{'active':imageStatus === '2'}"  @click="fetchDataByStatus('2')">
            <span></span>
            制作失败
          </div>
        </div>
      </div>
    </div>
    <div class="imagesbox">
      <template v-if="rows && rows.length>0">
          <imageitem 
          v-for="item in rows" 
          :key="item.id" 
          :config="{'type':item.status,'version':item.version,'info':item.info,'name':item.name,'createTime': dayFormat(item.createTime),'createUser':item.createUser,'id':item.id,'imageName':item.imageName,'path':item.tarImageMinio}"
          @deleteImage="deleteImage"
        />
        <div
         class="paging"
         >
        <CustomPagination 
          :total="total"
          :pageSize = "pageSize"
          :currentPage = 'currentPage'
          @update:currentPage="pageChange"
        />
    </div>
      </template> 
      <template v-else>
        <div class="noResults">
          暂无数据
        </div>
      </template>
    </div>

    <!-- 新增镜像弹框 -->
    <modal
      name="imagemodal"
      width="50%"
      :click-to-close="false"
      :height="'auto'"
      class="modal"
      data-testid="mvc__card"
      >
    <div class="images">
      <div class="header">
        新增镜像<i class="icon icon-close" @click="$modal.hide('imagemodal')"></i>
      </div>
      <div class="imagecontainer">
        <imageedit 
          :model="'edit'" 
          :rules="fvGetAndReportPathRules('imageform')"
          @close="close"
          />
      </div>
    </div>
    </modal>
    <!-- 删除镜像弹框 -->
    <DeleteModal 
      ref="deleteImage"
      label="确定删除该镜像?"
      @disable="deleteAImage"
    />
  </div>
</template>

<style lang='scss' scoped>
.images-page {
  background-color: #ffffff;
  margin:16px;
  height: 100%;
  padding: 0 24px 24px 24px !important;
  overflow: hidden;
  .actions {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:space-between;
    margin-bottom:16px;
    .search {
      display:flex;
      flex-direction:row;
      align-items: center;
      input {
        width: 280px;
        height: 32px;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        padding:6px 8px 6px 8px;
        margin-right:6px;
      }
      .imagesStatuLabels {
        display:flex;
        flex-direction:row;
        align-items:center;
        gap: 20px;
        margin-left:80px;
        .status {
          height: 30px;
          opacity: 1;
          /** 文本1 */
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 30px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 8px;
          cursor: pointer;
          user-select: none;
          span {
            width:11px;
            height:11px;
            display:inline-block;
            border-radius:50%;
            margin-right:6px;
          }
        }
        
        .status:nth-of-type(1){
          span{
            background-color:rgba(235, 151, 68, 1);
          }
        }
        .status:nth-of-type(1).active{
          background-color:rgba(235, 151, 68, .4);
          color: #ffffff;
        }
        .status:nth-of-type(2){
          span{
            background-color:rgba(73, 92, 197,1);
          }
        }
        .status:nth-of-type(2).active{
          background-color:rgba(69, 152, 255, .4);
          color: #ffffff;
        }
        .status:nth-of-type(3){
          span{
            background-color:rgba(153, 153, 153, 1);
          }
        }
        .status:nth-of-type(3).active{
          background-color:rgba(153, 153, 153, .4);
          color: #ffffff;
        }
      }
    }
    
  }
  .imagesbox {
    display: flex;
    flex-wrap: wrap;
    flex-basis: content;
    gap: 28px;
    height:calc(100% - 138px);
    overflow:auto;
    &::-webkit-scrollbar{
      width:3px !important;
    }
    &::-webkit-scrollbar-thumb{
      background: rgba(238, 238, 238, 1)  !important;
      border-radius:2.5px  !important;
    }
    .paging {
      width: 100%;
      margin-top: 4px;
      text-align: right;
    }
    .noResults {
      font-size: 24px;
      text-align: center;
      width: 100%;
      margin: 100px;
    }
  }
}

::v-deep{
  .images{
    .header{
      height: 20px;
      opacity: 1;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 20px;
      margin-bottom: 10px;
      color: var(--default);
      position: relative;
      text-align: center;
      i {
        position: absolute;
        right: 0px;
        top:3px;
        font-size: 14px;
        color: var(--lighterText);
        cursor: pointer;
      }
    }
    .imagecontainer {
      width: 100%;
      height: auto;
      padding: 22px 0px 0px 0px;
    }
  }
}
</style>


