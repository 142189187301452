<script>
import { Card } from '@components/Card';
export default {
  name:       'DeleteModal',
  components: { Card },
  props:      {
    componentTestid: {
      type:    String,
      default: 'deleteModal'
    },
    label:{
      type:    String,
      default: ''
    },
  },
  methods: {
    show() {
      this.$modal.show('deleteModal');
    },
    close() {
      this.$modal.hide('deleteModal');
    },
    disable() {
      this.$emit('disable');
    },
  }
};
</script>

<template>
  <modal
    class="remove-modal"
    name="deleteModal"
    :width="400"
    height="auto"
    styles="max-height: 100vh;"
    @closed="close"
  >
    <Card
      class="disable-auth-provider"
      :show-highlight-border="false"
    >
      <h4
        slot="title"
        class="text-default-text"
      >
        提示
      </h4>
      <div slot="body">
        <div class="mb-10">
          <p>{{ label }}</p>
        </div>
      </div>
      <template #actions>
        <button
          class="btn btn-border role-secondary"
          @click="close"
        >
          {{ t('generic.cancel') }}
        </button>
        <button
          class="btn role-primary bg-error"
          :data-testid="componentTestid + '-confirm-button'"
          @click="disable"
        >
          删除
        </button>
      </template>
    </Card>
  </modal>
</template>

<style lang='scss'>
  .disable-auth-provider {
    &.card-container {
      box-shadow: none;
    }
    #confirm {
      width: 90%;
      margin-left: 3px;
    }

    .remove-modal {
        border-radius: var(--border-radius);
        overflow: scroll;
        max-height: 100vh;
        & ::-webkit-scrollbar-corner {
          background: rgba(0,0,0,0);
        }
    }

    .actions {
      text-align: right;
    }

    .card-actions {
      display: flex;

      .spacer {
        flex: 1;
      }
    }
  }
</style>
