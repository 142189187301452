<template>
    <div class="common">
        <CommonAdmin title="训练任务" :headers="headers" :url="url" type="task" :customRows="customRows"
            :tableAction="tableAction" />
    </div>
</template>
  
<script>
import CommonAdmin from '@shell/components/CommonAdmin'
import { datasetApi } from '@shell/components/common/api'
export default {
    name: 'TrainTask',
    components: {
        CommonAdmin
    },
    data() {
        return {
            headers: [
                { name: 'name', label: '名称' },
                { name: 'desc', label: '描述' },
                { name: 'status', label: '状态' },
                { name: 'statusMessage', label: '状态消息' },
                { name: 'statusTime', label: '状态时间戳' },
                { name: 'createTime', label: '创建时间' },
                { name: 'btnactions', label: '操作' }
            ],
            url: datasetApi.traintask,
            customRows: {},
            tableAction: [
                { key: 'query', text: "详情", btnclass: 'multi', iconclass: 'error', clickEvent: 'queryDetail', eventParams: [] },
                { key: 'start', text: "开始训练", btnclass: 'copy', iconclass: 'play', clickEvent: 'startTrain', eventParams: [] },
                { key: 'export', text: "导出", btnclass: 'multi', iconclass: 'download', clickEvent: "exportTask", eventParams: [] },
                { key: 'edit', text: "编辑", btnclass: 'edit', iconclass: 'edit', clickEvent: "addNewModal", eventParams: ['edit'] },
                { key: 'del', text: "删除", btnclass: 'delete', iconclass: 'delete', clickEvent: 'delItemById', eventParams: [] },
            ]
        }
    },
    created() {
        this.fetchdata()
    },
    methods: {
        fetchdata() {
            const url = datasetApi.algo + '/?createUser=cstor' + '&page=1' + '&size=10000'
            this.$axios.get(url).then(res => {
                const datasetrows = res.data.data.records.filter(algo => algo.type === 2)
                this.customRows.trainenv = datasetrows.map((item) => {
                    return {
                        label: item.name,
                        value: item
                    };
                });
            })
            const traindataurl = datasetApi.traindata + '/?createUser=cstor' + '&page=1' + '&size=10000'
            this.$axios.get(traindataurl).then(res => {
                const traindatasetrows = res.data.data.records
                this.customRows.traindata = traindatasetrows.map((item) => {
                    return {
                        label: item.name,
                        value: item
                    };
                });
            })
        },
    }
}
</script>
  